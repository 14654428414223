import React, { useState } from 'react'
import {Button} from 'react-admin'
import SaveIcon from '@material-ui/icons/Save';
import {useParams, useHistory} from "react-router-dom";

export const StorageCreate = ({ staticContext, ...props }) => {
    const [file, setFile] = useState();
    let { id } = useParams();
    let history = useHistory();

    localStorage.setItem('id_pratice', (id));
    const id_pratice = localStorage.getItem('id_pratice')

    const onChangeHandler = e => {
      setFile(e.target.files);
    }

    const onSubmitHandler = async e => {
        e.preventDefault();
        const formData = new FormData();
        Object.values(file).map((fileItem => formData.append(`file`, fileItem)))
        await fetch(process.env.REACT_APP_API_ORIGIN + '/minio', {
         method: 'POST',
          body: formData,
          headers: {
            'id_pratice': id_pratice
        }
         });

         history.goBack();
    }

    const mystyle2 ={
      width: '70vw',
      height: '70vh',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    };

    const mystyle = {
      borderRadius: '10px',
      width: '800px',
      height: '400px',
      border: '1px solid',
  padding: '10px',
  boxShadow: '5px 10px #d9d9d9',
    };

    const mystyleI ={
      width: '300px',
      backgroundColor: '#3498db',
  borderRadius: '5px',
  color: '#fff',
  cursor: 'pointer',
  margin: '10px',
  padding: '6px 20px',
  position: 'relative',
 top: '150px',
 right:'-220px',
    };

    const mystyleB ={
      backgroundColor: '#3498db',
  borderRadius: '5px',
  color: '#fff',
  cursor: 'pointer',
  margin: '10px',
  padding: '6px 20px',
  position: 'relative',
 top: '320px',
 right:'-350px',
};

  return (
    <>
      <form enctype='multipart/form-data' onSubmit={onSubmitHandler}>
        <div style={mystyle2} className="custom-file mb-4">
          <div style={mystyle}>
          <input style={mystyleI} name="file[]" multiple type="file" className="custom-file-input" id="customFile" onChange={onChangeHandler} />
          <Button style={mystyleB}
          type="submit"
          value="Salvar"
          className="btn btn-primary btn-block mt-4"
          >
          <SaveIcon/>
          </Button>
        </div>
        </div>
      </form>
    </>
  );
}
