import { Button } from '@material-ui/core'
import { GetApp } from '@material-ui/icons'
import React from 'react'
import { CSVLink } from 'react-csv'

function CsvDownloadButton(props) {
    const [data, setData] = React.useState([])
    React.useEffect(() => {
        const contentFormated = props.data.map(dataParam => {
            return {
                key: decodeURIComponent(dataParam.form[0]),
                value: decodeURIComponent(dataParam.form[1])
            }
        })

        setData(contentFormated)
    }, [])

    return data && (
        <CSVLink data={data} enclosingCharacter={``} separator={";"}>
            <Button
                variant="contained"
                color="default"
                startIcon={<GetApp />}
            >{props.label}</Button>
        </CSVLink>
    )
}

export default CsvDownloadButton
