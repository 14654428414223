import * as React from "react";
import FormatShapesIcon from '@material-ui/icons/FormatShapes';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CodeIcon from '@material-ui/icons/Code';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import {
    Button,
    Link,
} from 'react-admin';

const cardStyle3 = {
    boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
    transition: '0.3s',
    width: '23%',
    borderRadius: '5px',
    position: 'absolute',
    left:'910px',
    top:'0px',
};

const cardStyle2 = {
    boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
    transition: '0.3s',
    width: '23%',
    borderRadius: '5px',
    position: 'absolute',
    left:'460px',
    top:'0px',
};

const cardStyle = {
    boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
    transition: '0.3s',
    width: '23%',
    borderRadius: '5px',
};

const containerStyle = {
    padding: '2px 16px',
};

const buttonStyle = {
    width:'100%',
    height:'200px',
    fontsize:'20px',
    backgroundColor: '#1E90FF',
    color:'white',
    borderColor: '#259b4c',
    borderRadius: '5px',
    display: 'flex',
    justifyContent: 'center',
  };

export const DemandsList = props => {
    return (
        <form>
            <div style={{position:'relative'}}>
            <div style={cardStyle}>
            <Button
            label='Demandas Dev'
   key='demandsdev'
   component={Link}
   to={{
   pathname: "/demandsdev/1/show",
   }}
   style={buttonStyle}
   >
   <CodeIcon  style={{color:'white', width:'50px', height:'50px'}}/>
   </Button>
  <div style={containerStyle}>
     <Button
            label='Criar'
   key='Create'
   component={Link}
   to={{
   pathname: "/demandsdev/create",
   }}
   >
   <AddIcon  style={{color:'#1E90FF', width:'30px', height:'30px'}}/>
   </Button>
    <hr/>
     <Button
            label='Categoria de Demanda Dev'
   key='demandsdev'
   component={Link}
   to={{
   pathname: "/category/demanddev/1/show",
   }}
   >
   <EditIcon  style={{color:'#1E90FF', width:'30px', height:'30px'}}/>
   </Button>
    <hr/>
  </div>
</div>

<div style={cardStyle2}>
            <Button
            label='Demandas Modelagem'
   key='Demand_model'
   component={Link}
   to={{
   pathname: "/demandmodeling/1/show",
   }}
   style={buttonStyle}
   >
   <FormatShapesIcon  style={{color:'white', width:'50px', height:'50px'}}/>
   </Button>
  <div style={containerStyle}>
  <Button
            label='Criar'
   key='Create'
   component={Link}
   to={{
   pathname: "/demandmodeling/create",
   }}
   >
   <AddIcon  style={{color:'#1E90FF', width:'30px', height:'30px'}}/>
   </Button>
    <hr/>
     <Button
            label='Categoria de Demanda de Modelagem'
   key='category/demandmodeling'
   component={Link}
   to={{
   pathname: "/category/demandmodeling/1/show",
   }}
   >
   <EditIcon  style={{color:'#1E90FF', width:'30px', height:'30px'}}/>
   </Button>
   <hr/>
  </div>
</div>

<div style={cardStyle3}>
            <Button
            label='Demandas Qualidade'
   key='demandquality'
   component={Link}
   to={{
   pathname: "/demandquality/1/show",
   }}
   style={buttonStyle}
   >
   <CheckBoxIcon  style={{color:'white', width:'50px', height:'50px'}}/>
   </Button>
  <div style={containerStyle}>
  <Button
            label='Criar'
   key='Create'
   component={Link}
   to={{
   pathname: "/demandquality/create",
   }}
   >
   <AddIcon  style={{color:'#1E90FF', width:'30px', height:'30px'}}/>
   </Button>
    <hr/>
     <Button
            label='Categoria de Demanda de Qualidade'
   key='Demand_quality'
   component={Link}
   to={{
   pathname: "/category/demandquality/1/show",
   }}
   >
   <EditIcon  style={{color:'#1E90FF', width:'30px', height:'30px'}}/>
   </Button>
   <hr/>
  </div>
</div>
</div>
   </form>
);
        }
