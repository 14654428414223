import * as React from "react";
import { useMediaQuery } from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import {
    List,
    Datagrid,
    TextField,
    Filter,
    SimpleList,
    ReferenceInput,
    SelectInput,
    TextInput,
    Edit,
    SimpleForm,
    ListButton,
    Create,
    AutocompleteInput,
} from 'react-admin';

const LanguageFilter = (props) => (
    <Filter {...props}>
        <ReferenceInput label="Buscar" source="q" reference="Languages" alwaysOn allowEmpty emptyValue='' resettable>
    <AutocompleteInput optionText="name" />
</ReferenceInput>
        <ReferenceInput label="Idioma" source="name" reference="Languages" allowEmpty>
            <SelectInput optionText="name" />
        </ReferenceInput>
    </Filter>
);

export const LanguageList = props => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
        <List filters={<LanguageFilter />} {...props}>
            {isSmall ? (
                <SimpleList
                    primaryText={record => record.title}
                    secondaryText={record => `${record.views} views`}
                    tertiaryText={record => new Date(record.published_at).toLocaleDateString()}
                />
            ) : (
                    <Datagrid rowClick="edit">
                        <TextField source="id" />
                        <TextField source="name" />
                    </Datagrid>
                )}
        </List>
    );
}
export const LanguageEdit = props => (
    <Edit {...props}>
        <SimpleForm>
        <TextField source="id"/>
            <TextInput source="name" fullWidth/>
            <ListButton label="Back" icon={<ChevronLeft />}/>
        </SimpleForm>
    </Edit>
);

export const LanguageCreate = props => (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="name" fullWidth/>
      </SimpleForm>
   </Create>
);
