import React, { useContext, useState } from 'react'
import PostCreateToolbar from './PostCreateToolbar'
import '../../../css/EntryContent.css';
import {
    SimpleForm,
    ReferenceInput,
    Title,
    AutocompleteInput,

} from 'react-admin';

import LocalizationContext from '../../../contexts/LocalizationContext'

import axios from 'axios'
import * as CSV from 'csv-string'
import * as XLSX from 'xlsx'

function EntryContent(props) {
    const {
        pratice,
        setContent,
        setLanguage,
        language,
        setStep,
        step,
        token
    } = useContext(LocalizationContext)

    const [contentArea, setContentArea] = useState('')
    const [allowedFileTypes,] = useState([
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'text/csv'
    ])

    const config = {
        headers: {
            authorization: `Bearer ${token}`,
        }
    }

    React.useEffect(() => {
        localStorage.setItem('algetec-storage-expression', JSON.stringify([]))
    }, [])

    const handleChangeLanguage = (id) => {
        axios.get(`${process.env.REACT_APP_API_ORIGIN}/languages/${id}`, config)
            .then(({ data }) => setLanguage(data))
    }

    const handleImportationCsv = ({ target }) => {
        const file = target.files[0]
        handleImportCsv(file)
    }

    const handleImportationXlsx = ({ target }) => {
        const file = target.files[0]
        handleImportExcel(file)
    }

    const handleImportCsv = (file) => {
        var reader = new FileReader();
        reader.onload = function () {
            // const item = reader.result.split('\n').map(data => encodeURIComponent(data))
            setContentArea(reader.result)
        };

        reader.readAsBinaryString(file);
    }

    const handleImportExcel = (file) => {
        const reader = new FileReader();

        reader.onload = () => {
            const bstr = reader.result
            const wb = XLSX.read(bstr, { type: 'binary' })
            const wsname = wb.SheetNames[0]
            const ws = wb.Sheets[wsname]
            const data = XLSX.utils.sheet_to_csv(ws, { header: 1, FS: ";" })

            setContentArea(data)
        }

        reader.readAsBinaryString(file)
    };

    const handleChangeContent = (value) => {
        setContentArea(value)
    }
    return (
        <div className="pai" style={{ position: 'relative' }}>
            <Title title="Localizações" />
            <SimpleForm toolbar={
                <PostCreateToolbar
                    disabled={(contentArea && language) ? true : false}
                    save={() => {
                        const contentAreaFormated = contentArea.split('\n').filter(data => data !== '').join('\n')
                        setContent(CSV.parse(contentAreaFormated, ';'))
                        setStep(step + 1)
                    }}
                />
            }>
                <div style={{ backgroundColor: 'white', width: 'auto', position: 'relative', padding: '2em' }}>
                    <div>
                        <h1>Entrada de Localização</h1>
                        <ReferenceInput
                            label="Idioma"
                            source="fk_language"
                            reference="Languages"
                            onChange={handleChangeLanguage}
                            allowEmpty
                            suggestionLimit={7}
                            emptyText=''
                            emptyValue=''
                            resettable
                        >
                            <AutocompleteInput optionText="name" />
                        </ReferenceInput>
                    </div>
                    <form>
                        <div>
                            <p>Versão da Entrada: </p>
                            <p>Nome da Prática: {pratice?.name}</p>
                            <p>Versão da Prática: {pratice?.version}</p>
                            <h3>Conteúdo</h3>
                            <div style={{ textAlign: "right", width: "80%" }}>
                                <label className="import" for="import_csv">Importar CSV
                                    <input
                                        className="fileImport"
                                        type="file"
                                        onChange={handleImportationCsv}
                                        id="import_csv"
                                        name="import_csv"
                                    ></input>
                                </label>
                                <label className="import" for="import_xlsx">Importar XLSX
                                    <input
                                        className="fileImport"
                                        type="file"
                                        onChange={handleImportationXlsx}
                                        id="import_xlsx"
                                        name="import_xlsx"
                                        accept={allowedFileTypes.toString(',')}
                                    ></input>
                                </label>
                            </div>
                            <textarea
                                class="area1"
                                name="story"
                                rows="35"
                                cols="93"
                                style={{ padding: '2em' }}
                                value={contentArea}
                                onChange={({ target: { value } }) => handleChangeContent(value)}
                            ></textarea>
                        </div>
                    </form>
                </div>
            </SimpleForm>
        </div>
    )
}

export default EntryContent
