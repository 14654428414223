import axios from 'axios'

const authItem = localStorage.getItem('auth')
const auth = JSON.parse(authItem)

const API = axios.create({
  baseURL: `${process.env.REACT_APP_API_ORIGIN}/`,
  headers: {
    authorization: `Bearer ${auth?.token}`
  }
})

export default API