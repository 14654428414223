const AuthProvider = {
    login: async ({ username, password }) => {
      const request = new Request(`${process.env.REACT_APP_API_ORIGIN}/auth`, {
        method: 'POST',
        body: JSON.stringify({ email: username, password }),
        headers: new Headers({ 'Content-Type': 'application/json' })
      })

      return await fetch(request)
        .then(response => {
          if (response.status < 200 || response.status >= 300) {
            throw new Error(response.statusText)
          }

          return response.json()
        })
        .then(({ token, user }) => {
          localStorage.setItem('auth', JSON.stringify({
            token,
            user
          }))
        })
        .catch((error) => {
          throw new Error(error)
        })
    },
    checkError: (error) => {
      const status = error.status
      if (status === 401 || status === 403) {
        localStorage.removeItem('auth')
        return Promise.reject({ message: true })
      }

      return Promise.resolve()
    },
    checkAuth: () => {
      const auth = JSON.parse(localStorage.getItem('auth'))
      const token = auth && auth.token
      return token
        ? Promise.resolve()
        : Promise.reject({ redirectTo: '/login' })
    },
    logout: () => {
      localStorage.removeItem('auth')
      return Promise.resolve()
    },
    getPermissions: () => {
      const auth = JSON.parse(localStorage.getItem('auth'))
      const permissions = auth && auth.permissions

      return permissions
        ? Promise.resolve(permissions)
        : Promise.reject(new Error())
    },
    getIdentity: () => {
      const auth = JSON.parse(localStorage.getItem('auth'))
      const profile = auth.user

      if (profile) {
        return Promise.resolve({
          username: profile.username,
          email: profile.email
        })
      }

      return Promise.resolve()
    }
  }

  export default AuthProvider
