import React, { useEffect, useState } from 'react'
import ReactExport from 'react-export-excel'
import { GetApp } from '@material-ui/icons';
import { Button } from '@material-ui/core'

const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn

function XlsxDownloadButton(props) {
    const [data, setData] = useState([])
    const [header, setHeader] = useState(props?.data[0].form)
    useEffect(() => {

        const dataFormatted = props?.data.map((dataParam, index) => {
            if (index === 0) {
                return null
            }
            return {
                key: decodeURIComponent(dataParam.form[0]),
                value: decodeURIComponent(dataParam.form[1])
            }
        })

        console.debug(dataFormatted)

        const dataFiltered = dataFormatted.filter(dataParam => dataParam !== null)
        setData(dataFiltered)
    }, [])



    return data && (
        <ExcelFile element={
            <Button
                variant="contained"
                color="default"
                startIcon={<GetApp />}
            >{props?.label}</Button>
        }
            fileExtension={props?.fileExtension}
        >
            <ExcelSheet data={data} name="Conteúdo">
                <ExcelColumn label={decodeURIComponent(header[0]) || ''} value="key"></ExcelColumn>
                <ExcelColumn label={decodeURIComponent(header[1]) || ''} value="value"></ExcelColumn>
            </ExcelSheet>
        </ExcelFile>
    )
}

export default XlsxDownloadButton
