import * as React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import XlsxDownloadButton from '../../exporter/XlsxDownloadButton'
import CsvDownloadButton from '../../exporter/CsvDownloadButton'
import {
    Container,
    Grid,
    Paper,
    InputLabel,
    FormControl,
    TextField,
    Input,
    Button,
    Box
} from '@material-ui/core'

import { useHistory } from 'react-router-dom'

import LocationContext from '../../../contexts/LocalizationContext'
import { Title } from 'react-admin';

import { AWS, LOCALIZATION } from '../../../services/api'

const useStyles = makeStyles({
    root: {
        flexGrow: 1
    },
    paper: {
        padding: '1.5em'
    },
    select: {
        width: '100%'
    },
    contentField: {
        width: '100%'
    }
})

function EntryVersion() {
    const history = useHistory()
    const classes = useStyles()

    const {
        pratice,
        language,
        translator,
        content,
        auth,
        localization,
        translate,
        setContent
    } = React.useContext(LocationContext)

    const actualDate = () => new Date(Date.now()).toLocaleDateString()

    const [contentFormated, setContentFormated] = React.useState()

    React.useEffect(() => {
        const valuesWithoutBlankValue = content.filter(contentValue => contentValue.form[1] !== '')
        const valuesWithBlankValue = content.filter(contentValue => contentValue.form[1] === '')
        const contentSorted = [...valuesWithoutBlankValue, ...valuesWithBlankValue]

        const contentFormatedd = contentSorted?.map(contentParam => {

            return `${decodeURIComponent(contentParam?.form[0])};${decodeURIComponent(contentParam?.form[1])}`
        }).join('\n')


        setContentFormated(contentFormatedd)

    }, [])

    // React.useEffect(() => content?.map((translate) => { console.debug(translate.key) }), [])

    const handleClickDoneButton = async () => {
        const formData = new FormData()

        const propDataFormated = contentFormated.split('\n').map(dataParam => `${dataParam}`)


        const csvFile = new File(
            [propDataFormated?.join('\n')],
            'file.csv',
            { type: 'text/csv' }
        )

        formData.append('file', csvFile)

        if (localization?.fk_localization) {
            const { data: { aws } } = await AWS.post(formData)

            await LOCALIZATION.put(localization.fk_localization, { csv_file: aws })
        }

        localStorage.setItem('algetec-storage-expression', JSON.stringify([]))
        history.push('Localizations')
    }

    return (
        <React.Fragment>
            <Title title="Localizações" />
            <Container maxWidth className={classes.root}>
                <Paper className={classes.paper}>
                    <Grid container spacing={1}>
                        <Grid md item>
                            <p>Nome da prática: {pratice?.name}</p>
                            <p>Tradutor: {translator}</p>
                            <p>Revisor: {auth?.username}</p>
                            <p>Data da conclusão: {actualDate()}</p>
                        </Grid>
                        <Grid md item>
                            <Grid container spacing={2} >
                                <Grid item md={6}>
                                    <FormControl variant="filled" className={classes.select}>
                                        <InputLabel>Idioma</InputLabel>
                                        <Input value={language?.name} disabled />
                                    </FormControl>
                                </Grid>
                                <Grid item md={6}>
                                    <FormControl variant="filled" className={classes.select}>
                                        <InputLabel>Versão de entrada</InputLabel>
                                        <Input value='' disabled />
                                    </FormControl>
                                </Grid>
                                <Grid item md={6}>
                                    <FormControl variant="filled" className={classes.select}>
                                        <InputLabel>Versão da prática</InputLabel>
                                        <Input value={pratice?.version} disabled />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container>
                        <Grid item md>
                            <h3>Conteúdo</h3>
                            <TextField
                                className={classes.contentField}
                                multiline
                                minRows={10}
                                value={contentFormated}
                            />
                        </Grid>
                    </Grid>

                    <Box marginTop={2}>
                        <Grid container spacing={2}>
                            <Grid item>
                                <XlsxDownloadButton
                                    label="Exportar xlsx"
                                    data={content}
                                />
                            </Grid>
                            <Grid item>
                                <CsvDownloadButton
                                    label="Exportar csv"
                                    data={[
                                        ...content
                                    ]}
                                    fileExtension="csv"
                                />
                            </Grid>
                            <Grid item>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleClickDoneButton}
                                >Concluir</Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Paper>
            </Container>
        </React.Fragment>
    )
}

export default EntryVersion
