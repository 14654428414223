import * as React from "react";
import { ImageField, Notification, defaultTheme, Login } from 'react-admin';
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import logo2 from '../images/logo2.png';

const MyLoginPage = ({ theme }) => {


  return (
      <ThemeProvider theme={createMuiTheme(defaultTheme)}>
        <ImageField source="url" title="logo" />
    <div>
    <img src={logo2} alt="logo" width="420"  title="alge"/>
    </div>
          <Login
        backgroundImage=""
    />
          <Notification />
      </ThemeProvider>
  );
};

export default MyLoginPage;
