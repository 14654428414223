import API from '../config'

const LOCALIZATION = {
    put: (id, payload) => API.put(`/localizations/${id}`, payload),
    post: (payload) => API.post('/localizations', payload),
    putFromFile: (id, payload) => API.put(`/localizations/from-file/${id}`, payload),
    putCsvEndpoint: (id, payload) => API.put(`/localizations/csv-endpoint/${id}`, payload)
}

export {
    LOCALIZATION
}
