import * as React from "react";
import '../../../css/locationinput.css';
import {
    SimpleForm,
    ReferenceInput,
    SelectInput,
    Button,
    Link,
    Toolbar,
    AutocompleteInput,
} from 'react-admin';
import { useHistory } from "react-router-dom";

const PostCreateToolbar = props => (
    <Toolbar {...props} >
        <Button
            label="PROSSEGUIR"
            key='NewEntry'
            component={Link}
            to={{
                pathname: "/NewEntry",
            }}
        >
        </Button>
    </Toolbar>
);

export const NVLICreate = props => {

    const history = useHistory()
    React.useEffect(() => {
        const userAuthenticated = localStorage.getItem('auth')
        if (!userAuthenticated) {
            history.push('/login')
        }
    })

    const [pratice, setPratice] = React.useState()
    const [content, setContent] = React.useState()
    const [language, setLanguage] = React.useState()


    const handleImportCsv = ({ target }) => {
        var reader = new FileReader();
        reader.onload = function () {
            setContent(reader.result);
        };

        reader.readAsBinaryString(target.files[0]);
    }

    React.useEffect(() => {
        sessionStorage.setItem('localization/content', content)
        sessionStorage.setItem('localization/language', language)
    }, [content, language])

    React.useEffect(() => {
        const praticeLocalStorage = JSON.parse(localStorage.getItem('pratices'))
        setPratice(praticeLocalStorage)
    }, [])

    return (
        <div className="pai" style={{ position: 'relative' }}>
            <SimpleForm toolbar={<PostCreateToolbar />}>
                <div style={{ backgroundColor: 'white', width: 'auto', position: 'relative', padding: '2em' }}>
                    <div>
                        <h1>Entrada de Localização</h1>
                        <ReferenceInput
                            label="Idioma"
                            source="fk_language"
                            reference="Languages"
                            inputProps={{
                                onChange: ({ target }) => setLanguage(target.value)
                            }}
                            allowEmpty
                            resettable
                        >
                            <AutocompleteInput optionText="name" style={{ width: '20%' }} />
                        </ReferenceInput>
                    </div>
                    <form>
                        <div>
                            <p>Versão da Entrada: </p>
                            <p>Nome da Prática: {pratice?.name}</p>
                            <p>Versão da Prática: {pratice?.version}</p>
                            <h3>Conteúdo</h3>
                            <div style={{ textAlign: "right", width: "80%" }}>
                                <button type="button">Importar CSV</button>
                                <input type="file" onChange={handleImportCsv} name="import_csv"></input>
                            </div>
                            <textarea
                                class="area1"
                                name="story"
                                rows="35"
                                cols="93"
                                style={{ padding: '2em' }}
                                value={content}
                                onChange={({ target }) => setContent(target.value)}
                            ></textarea>
                        </div>
                    </form>
                </div>
            </SimpleForm>
        </div>
    )
};
