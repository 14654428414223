import * as React from "react";
import { useMediaQuery } from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import {
    List,
    Datagrid,
    TextField,
    EditButton,
    Edit,
    Create,
    Filter,
    SimpleForm,
    SimpleList,
    ReferenceInput,
    SelectInput,
    TextInput,
    ListButton,
    AutocompleteInput,
} from 'react-admin';

const StakeCFilter = (props) => (
    <Filter {...props}>
       <ReferenceInput label="Buscar" source="q" reference="category/stakeholder" alwaysOn allowEmpty emptyValue='' resettable>
        <AutocompleteInput optionText="name" />
</ReferenceInput>
        <ReferenceInput label="Request" source="RequestId" reference="Request" allowEmpty>
            <SelectInput optionText="deadline" />
        </ReferenceInput>
    </Filter>
);

export const StakeCList = props => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
    <List filters={<StakeCFilter />} {...props}>
            {isSmall ? (
        <SimpleList
            primaryText={record => record.title}
            secondaryText={record => `${record.views} views`}
            tertiaryText={record => new Date(record.published_at).toLocaleDateString()}
        />
        ) : (
        <Datagrid>
        <TextField source="id"/>
        <TextField source="name"/>
        <EditButton />
        </Datagrid>
        )}
    </List>
);
        }

const StakeCTitle = ({ record }) => {
        return <span>Dev </span>;
    };

export const StakeCEdit = props => (
    <Edit {...props}>
        <SimpleForm>
        <TextInput source="name" fullWidth/>
            <ListButton label="Back" icon={<ChevronLeft />}/>
        </SimpleForm>
    </Edit>
);

export const StakeCCreate = props => (
        <Create title={<StakeCTitle />} {...props}>
          <SimpleForm>
        <TextInput source="name" fullWidth/>
          </SimpleForm>
       </Create>
    );
