import * as React from 'react';
import { Layout } from 'react-admin';
import MyAppBar from './MyAppBar';
import MyMenu from './Mymenu';
import { ProfileProvider } from '../pages/profile'

const MyLayout = (props) => (
<ProfileProvider>
<Layout {...props} appBar={MyAppBar} />
</ProfileProvider>

)

export default MyLayout;
