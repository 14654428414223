const ptBr = {
  Dashboard: {
    name: 'Painel de Controle |||| Painéis de Controle',
  },
  resources: {
    Request: {
      name: 'Pedido |||| Pedidos',
      fields: {
        number: 'Número',
        deadline: 'Prazo',
        status: 'Estado',
        fk_clients: 'Cliente',
      }
    },
    Pratice: {
      name: 'Prática |||| Práticas',
      fields: {
        name: 'Nome da Prática',
        version: 'Versão',
        deadline: 'Prazo da Prática',
        status: 'Status',
        skill: 'Disciplina',
        fk_skills: 'Disciplina',
        fk_stakeholder: 'Parte interessada',
        fk_request: 'Pedido'
      }
    },
    Infra: {
      name: 'Infra |||| Infras',
    },
    Demand: {
      name: 'Demanda |||| Demandas',
      fields: {
        Create: 'Criar',
      }
    },
    demandquality: {
      name: 'Demanda de Qualidade |||| Demandas de Qualidade',
      fields: {
        practices_id: 'Pratica',
        deadline: 'Prazo',
        description: 'Descrição',
        weight: 'Peso',
        status: 'Status',
        timing: 'Tempo',
        qa_id: 'Revisor',
        category_demands_qa: 'Categoria de Qualidade'
      }
    },
    demandmodeling: {
      name: 'Demanda de Modelagem |||| Demandas de Modelagem',
      fields: {
        fk_pratice: 'Pratica',
        deadline: 'Prazo',
        asset_name: 'Responsável',
        weight: 'Peso',
        referencie: 'Referencia',
        status: 'Status',
        timing: 'Tempo',
        fk_shaper: 'Modelador',
        fk_category_modeling: 'Categoria de Modelagem',
      }
    },
    demandsdev: {
      name: 'Demanda de Dev |||| Demandas de Dev',
      fields: {
        deadline: 'Prazo',
        description: 'Descrição',
        weight: 'Peso',
        fk_pratice: 'Pratica',
        fk_dev: 'Dev'
      }
    },
    'category/demandmodeling': {
      name: 'Categoria de Demanda de Modelagem |||| Categoria de Demandas de Modelagem',
      fields: {
        name: 'Nome da Categoria',
      }
    },
    'category/demanddev': {
      name: 'Categoria de Demanda de Dev |||| Categoria de Demandas de Dev',
      fields: {
        name: 'Nome da Categoria',
      }
    },
    'category/demandquality': {
      name: 'Categoria de Demanda de Qualidade |||| Categoria de Demandas de Qualidade',
      fields: {
        name: 'Nome da Categoria',
      }
    },
    Client: {
      name: 'Cliente |||| Clientes',
      fields: {
        name: 'Nome do Cliente',
        cpf_cnpj: 'CPF/CNPJ'
      }
    },
    User: {
      name: 'Usuário |||| Usuários',
      fields: {
        username: 'Nome do usuário',
        email: 'Email',
        fk_role: 'Cargo'
      }
    },
    Bug: {
      name: 'BUG |||| BUGS',
      fields: {
        subject: 'Assunto',
        description: 'Descrição'
      }
    },

    StakeHolder: {
      name: 'Parte Interessada |||| Partes Interessadas',
      fields: {
        name: 'Nome',
        email: 'E-mail',
        cpf: 'CPF',
        fk_clients: 'Cliente',
        fk_category_stakeholder: 'Categoria partes interessadas',
      }
    },

    Skill: {
      name: 'Disciplina |||| Disciplinas',
      fields: {
        name: 'Nome',
      }
    },
    Calendar: {
      name: 'Calendário |||| Calendário',
    },

    Translation: {
      name: 'Expressão |||| Expressões',
      fields: {
        keyname: 'Chave',
        value: 'Tradução',
        fk_language: 'Idioma',
        version: 'Versão',
        responsible: 'Tradutor',
        author: 'Autor',
        updated_at: 'Última modificação',
      }
    },
    Localizations: {
      name: 'Localização |||| Localizações',
      fields: {
        name: 'Prática',
        version: 'Versão da Prática',
        responsible: 'Responsável',
        csv_file: 'Arquivo CSV',
        updated_at: 'Última modificação',
      },
    },
    Keyname: {
      name: 'Chave |||| Chaves',
      fields: {
        name: 'Nome',
        created_at: 'Criado em',
        updated_at: 'Última modificação',
      }
    },
    'Request-Translation': {
      name: 'Solicitações |||| Solicitação',
      fields: {
        language: 'Idioma',
        practiceVersion: 'Versão da prática',
        author: 'Autor',
        translator: 'Tradutor',
        EstimatedDeliveryTime: 'Tempo de entrega estimado',
        incomingVersion: 'Versão de entrada',
      }
    },
    Languages: {
      name: 'Idioma |||| Idiomas',
      fields: {
        name: 'Idioma',
      }
    },

  }
}

export default ptBr
