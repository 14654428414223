import jsonServerProvider from 'ra-data-json-server'

import {
  fetchUtils
} from 'react-admin'

const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' })
  }

  const { token } = JSON.parse(localStorage.getItem('auth'))
  options.headers.set('Authorization', `Bearer ${token}`)

  return fetchUtils.fetchJson(url, options)
}

const addUserProfileOverrides = (dataProvider) => ({
  ...dataProvider,
  async updateUserProfile ({ data }) {
    const auth = JSON.parse(localStorage.getItem('auth'))

    auth.user = Object.assign({}, auth.user, data)

    localStorage.setItem(
      'auth',
      JSON.stringify({
        ...auth,
        id: 'unique_id'
      })
    )

    await fetch(process.env.REACT_APP_API_ORIGIN + '/profile', {
      method: 'PUT',
      body: JSON.stringify(data),
      headers: {
        Authorization: `Bearer ${auth.token}`,
        'Content-Type': 'application/json'
      }
    })

    return Promise.resolve({ data })
  }
})

export default addUserProfileOverrides(
  jsonServerProvider(process.env.REACT_APP_API_ORIGIN, httpClient)
)
