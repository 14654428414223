import * as React from "react";
import { useMediaQuery } from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
//import { ImportButton } from "react-admin-import-csv";
import jsonExport from 'jsonexport/dist';
import axios from 'axios';
import { Fragment } from 'react';
import {
  List,
  TextField,
  Datagrid,
  EditButton,
  Edit,
  Create,
  Filter,
  SimpleForm,
  SimpleList,
  TextInput,
  ListButton,
  useListContext,
  TopToolbar,
  CreateButton,
  sanitizeListRestProps,
  downloadCSV,
  BulkDeleteButton,
  BulkExportButton,
  ReferenceInput,
  SelectInput,
  Toolbar,
  Button,
  Link,
  useDataProvider,
  AutocompleteInput,
} from 'react-admin';
import { cloneElement } from 'react';
import { useHistory, useParams } from "react-router-dom";

const ListActions = (props) => {
  const {
    className,
    exporter,
    filters,
    maxResults,
    ...rest
  } = props;
  const {
    resource,
    displayedFilters,
    filterValues,
    basePath,
  } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters && cloneElement(filters, {
        resource,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
      <CreateButton basePath={basePath} label="Nova Expressão" />
    </TopToolbar>
  );
};


const TranslFilter = (props) => (
  <Filter {...props}>
      <ReferenceInput label="Buscar" source="q" reference="Translation" alwaysOn allowEmpty emptyValue='' resettable>
        <AutocompleteInput optionText="value" />
</ReferenceInput>
    <ReferenceInput source="keyname" label="Chave" reference="Translation" allowEmpty>
      <SelectInput optionText="keyname" />
    </ReferenceInput>
    <ReferenceInput source="value" label="Tradução" reference="Translation" allowEmpty>
      <SelectInput optionText="value" />
    </ReferenceInput>
    <ReferenceInput source="fk_language" label="Idioma" reference="Translation" allowEmpty>
      <SelectInput optionText="fk_language" />
    </ReferenceInput>
    <ReferenceInput source="version" label="Versão" reference="Translation" allowEmpty>
      <SelectInput optionText="version" />
    </ReferenceInput>
    <ReferenceInput source="responsible" label="Tradutor" reference="Translation" allowEmpty>
      <SelectInput optionText="responsible" />
    </ReferenceInput>
    <ReferenceInput source="author" label="Autor" reference="Translation" allowEmpty>
      <SelectInput optionText="author" />
    </ReferenceInput>
  </Filter>
);

const exporter = Translation => {
  const postsForExport = Translation.map(Translation => {
    const { backlinks, created_at, updated_at, ...postForExport } = Translation;
    return postForExport;
  });
  jsonExport(postsForExport, {
    headers: ['id', 'key', 'translation', 'language', 'version', 'translator', 'updated_at'],
    rowDelimiter: ';'
  }, (err, csv) => {
    downloadCSV(csv, 'Translation');
  });
};

const PostBulkActionButtons = props => (
  <Fragment>
    <BulkExportButton {...props} />
    <BulkDeleteButton {...props} />
  </Fragment>
);

export const TranslList = props => {
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
  return (
    <div className="pai">
      <div>
        <List bulkActionButtons={<PostBulkActionButtons />} exporter={exporter} actions={<ListActions />} filters={<TranslFilter />} {...props}>
          {isSmall ? (
            <SimpleList
              primaryText={record => record.title}
              secondaryText={record => `${record.views} views`}
              tertiaryText={record => new Date(record.published_at).toLocaleDateString()}
            />
          ) : (
            <Datagrid>
              <TextField source="keyname.name" label='Chaves' multiline rowsMax={4} />
              <TextField source="value" multiline rowsMax={4} />
              <TextField source="fk_language" multiline rowsMax={4} />
              <TextField source="version" multiline rowsMax={4} />
              <TextField source="responsible" multiline rowsMax={4} />
              <TextField source="author" multiline rowsMax={4} />
              <TextField source="updated_at" multiline rowsMax={4} />
              <EditButton label="Criar nova versão" />
            </Datagrid>
          )}
        </List>
      </div>
    </div>
  );
}

export const TranslEdit = props => {
  const provider = useDataProvider()
  const { id } = useParams()

  const [record, setRecord] = React.useState({})

  const auth = localStorage.getItem('auth')
  const { user: { username }, token } = JSON.parse(auth)

  React.useEffect(() => {
    provider.getOne('translation', { id })
      .then(({ data }) => {
        axios.get(`${process.env.REACT_APP_API_ORIGIN}/translates/language/${data.fk_language}/keyname/${data.fk_keyname}/versions`, {
          headers: {
            authorization: `Bearer ${token}`,
          }
        }).then((res) => {
          setRecord({
            ...data,
            value: null,
            version: res.data.forecast
          })
        })
      })

  }, [])

  return (
    <Create {...props} record={record}>
      <SimpleForm>
        <ReferenceInput source="fk_keyname" label="Chave" reference="Keyname" allowEmpty>
          <SelectInput disabled optionText="name" />
        </ReferenceInput>
        <TextInput source="value" fullWidth multiline rowsMax={4} />
        <ReferenceInput source="fk_language" reference="Languages" allowEmpty>
          <SelectInput disabled optionText="name" />
        </ReferenceInput>
        <TextInput disabled source="version" fullWidth multiline rowsMax={4} />
        <TextInput disabled source="responsible" fullWidth multiline rowsMax={4} />
        <TextInput source="author" fullWidth multiline disabled rowsMax={4} defaultValue={username} />
        <img style={{width:'200px',height:'200px'}} alt="exibição da imagem" src="https://thumbs.dreamstime.com/b/texto-vermelho-do-selo-do-exemplo-43363006.jpg" />
        <ListButton label="Voltar" icon={<ChevronLeft />} />
      </SimpleForm>
    </Create>
  );
}

/*export const TranslCreate = props => {
  const auth = localStorage.getItem('auth')
  const { user: { username } } = JSON.parse(auth)

  return (
    <Create {...props}>
      <SimpleForm redirect={false}>
        <ReferenceInput source="fk_keyname" label="Chave" reference="Keyname" allowEmpty>
          <SelectInput optionText="name" />
        </ReferenceInput>
        <TextInput source="value" fullWidth multiline rowsMax={4} />
        <ReferenceInput source="fk_language" reference="Languages" allowEmpty>
          <SelectInput optionText="name" />
        </ReferenceInput>
        <TextInput source="version" fullWidth multiline rowsMax={4} />
        <TextInput source="responsible" fullWidth multiline rowsMax={4} />
        <TextInput source="author" fullWidth multiline rowsMax={4} disabled defaultValue={username} />
        <ListButton label="Voltar" icon={<ChevronLeft />} />
      </SimpleForm>
    </Create>
  );
}*/

export const TranslCreate = props => {
  const handleKeynameChange = ({ target }) => sessionStorage.setItem('translation/key', target.value)
  const history = useHistory()

  const PostCreateToolbar = props => (
    <Toolbar {...props} >
      <Button
        label="Criar"
        key='NewTranslation'
        component={Link}
        onClick={handleNextPage}
      >
      </Button>
    </Toolbar>
  );

  const handleNextPage = () => {
    if (!sessionStorage.getItem('translation/key')) {
      return;
    }

    history.push('/NewTranslation')
  }

  return (
    <Create {...props}>
      <SimpleForm toolbar={<PostCreateToolbar />}>
        <h3>Criar Expressão</h3>
        <textarea placeholder="chave" style={{ resize: 'none' }} name="chave" rows="15" onChange={handleKeynameChange}  >
        </textarea>
      </SimpleForm>
    </Create>
  )
}

