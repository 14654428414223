import React, { useEffect, useState } from 'react'
import ReactExport from 'react-export-excel'
import { GetApp } from '@material-ui/icons';
import { Button } from '@material-ui/core'

const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn

function XlsxLocalization(props) {
    const [data, setData] = useState(props?.data)
    const [dataFormated, setDataFormated] = useState(props?.data)
    const [firstElements, setFirstElements] = useState()

    useEffect(() => {

        let index = 0
        const dataReplaced = data?.replaceAll(';', '\n').split('\n')
        const dataReplacedArray = dataReplaced?.map((dataParam) => {

            let actualItem = { key: dataReplaced[index], value: dataReplaced[index + 1] }
            index += 2
            return actualItem
        }).filter(data => data.key)

        setFirstElements(dataReplacedArray.shift())
        setDataFormated(dataReplacedArray)
    }, [])

    return (
        <ExcelFile element={
            <Button
                variant="contained"
                color="default"
                startIcon={<GetApp />}
            >{props?.label}</Button>
        }
            fileExtension={props?.fileExtension}
        >
            <ExcelSheet data={dataFormated} name="Conteúdo">
                <ExcelColumn label={firstElements?.key} value="key"></ExcelColumn>
                <ExcelColumn label={firstElements?.value} value="value"></ExcelColumn>
            </ExcelSheet>
        </ExcelFile>
    )
}

export default XlsxLocalization
