import * as React from 'react';
import { AppBar, Button, Link } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import BugReportIcon from '@material-ui/icons/BugReport';
import ForumIcon from '@material-ui/icons/Forum';
import MyUserMenu from './MyUserMenu'
import { Chip } from '@material-ui/core';

const useStyles = makeStyles({
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    spacer: {
        flex: 1,
    },
    chip: {
        color: '#fff',
        borderColor: '#fff'
    }
});

const buttonStyle = {
    width: '37px',
    height: '37px',
    fontsize: '20px',
    backgroundColor: '',
    bordercolor: '#259b4c',
    borderRadius: '30px',
    display: 'flex',
    justifyContent: 'center',
};

const buttonStyle2 = {
    width: '37px',
    height: '37px',
    fontsize: '20px',
    backgroundColor: '',
    bordercolor: '#259b4c',
    borderRadius: '30px',
    display: 'flex',
    justifyContent: 'center',

};

const MyAppBar = props => {
    const classes = useStyles();
    return (
        <AppBar {...props} userMenu={<MyUserMenu />}>
            <Typography
                variant="h6"
                color="inherit"
                className={classes.title}
                id="react-admin-title"
            />
            <span className={classes.spacer} />
            <div>
                <Chip
                    className={classes.chip}
                    label="V1.0.0"
                    variant="outlined"
                    size="small"
                />
            </div>
            <div>
                <Button
                    key='bug'
                    component={Link}
                    to={{
                        pathname: "/bug/create",
                    }}
                    style={buttonStyle}
                >
                    <BugReportIcon style={{ color: 'red' }} />
                </Button>
            </div>
            <div>
                <Button
                    key='Forum'
                    href="http://54.147.207.7:82/"
                    target="_blank"
                    style={buttonStyle2}
                >
                    <ForumIcon style={{ color: 'white' }} />
                </Button>
            </div>
        </AppBar>
    );
};

export default MyAppBar;
