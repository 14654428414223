import * as React from "react";
import '../../../css/translationRequest.css';
import {
    SimpleForm,
    Button,
    Toolbar,
} from 'react-admin';

import { useHistory } from "react-router-dom";

/*const ListActions = (props) => {
    const {
        className,
        exporter,
        filters,
        maxResults,
        ...rest
    } = props;
    const {
        resource,
        displayedFilters,
        filterValues,
    } = useListContext();
    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            {filters && cloneElement(filters, {
                resource,
                displayedFilters,
                filterValues,
                context: 'button',
            })}
        </TopToolbar>
    );
};

const exporter = Translation => {
    const postsForExport = Translation.map(Translation => {
        const { backlinks, created_at, updated_at, ...postForExport } = Translation;
        return postForExport;
    });
    jsonExport(postsForExport, {
        headers: ['id', 'key', 'translation', 'language', 'version', 'translator', 'updated_at'],
        rowDelimiter:';'
    }, (err, csv) => {
        downloadCSV(csv, 'Translation');
    });
};

const PostBulkActionButtons = props => (
    <Fragment>
        <BulkExportButton {...props}/>
        <BulkDeleteButton {...props} />
    </Fragment>
);

class Upload extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            file: null
        }
        this.handleChange = this.handleChange.bind(this)
    }
    handleChange(event) {
        this.setState({
            file: URL.createObjectURL(event.target.files[0])
        })
    }

    render() {
        return (
            <div class="imgpai">
                <img class="img2" alt="exibição da imagem a ser enviada" src={this.state.file} />
                <input class="img" type="file" onChange={this.handleChange} />
            </div>
        );
    }
}*/

const PostCreateToolbar = props => (
    <Toolbar {...props} >
        <Button
            label="Converter para Expressões"
        >
        </Button>
        <Button
            label="Salvar Expressões"
        >
        </Button>
    </Toolbar>
);

export const TRCreate = props => {
    const history = useHistory()
    React.useEffect(() => {
        const userAuthenticated = localStorage.getItem('auth')
        if (!userAuthenticated) {
            history.push('/login')
        }
    })

    return (
        <div className="pai" style={{ position: 'relative' }}>
            <SimpleForm toolbar={<PostCreateToolbar />}>
                <div style={{ backgroundColor: 'white', width: 'auto', position: 'relative' }}>
                    <form>
                        <div>
                            <p>Prática:</p>
                            <p>Tradutor:</p>
                            <p>Idioma:</p>
                            <p>Versão da Prática:</p>
                            <p>Versão da Entrada:</p>
                            <p>Data:</p>
                            <textarea class="area1" name="story" rows="35" cols="100">
                                It was a dark and stormy night...
                            </textarea>
                        </div>
                    </form>
                </div>
            </SimpleForm>
            <form>
                <div>
                    <textarea class="area1" name="story" rows="15" cols="43">
                        It was a dark and stormy night...
                    </textarea>
                    <br />
                    <textarea class="area1" name="story" rows="15" cols="43">
                        It was a dark and stormy night...
                    </textarea>
                    <img class="imag" alt="exibição da imagem a ser enviada" src="https://img.elo7.com.br/product/zoom/2C475C8/teste-teste.jpg" />
                </div>
                <hr />
                <br />
                <div>
                    <textarea class="area1" name="story" rows="15" cols="43">
                        It was a dark and stormy night...
                    </textarea>
                    <br />
                    <textarea class="area1" name="story" rows="15" cols="43">
                        It was a dark and stormy night...
                    </textarea>
                    <img class="imag1" alt="exibição da imagem a ser enviada" src="https://img.elo7.com.br/product/zoom/2C475C8/teste-teste.jpg" />
                </div>
            </form>
            <Button
                label="Converter para CSV"
            >
            </Button>
            <Button
                label="Marcar como concluída"
            >
            </Button>
        </div>
    );
}
