import React, { useEffect, useState } from 'react'
import LocationContext from '../../../contexts/LocalizationContext'
import _ from 'underscore';

import {
    SimpleForm,
    Toolbar,
    Button,
    Link,
    TextInput,
    Title,
} from 'react-admin'

import axios from 'axios'
import uuid from 'react-uuid'

import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';

import { Button as MaterialUIButton } from '@material-ui/core'

import { CHECK_DUPLICATE_EXPRESSION, TRANSLATE_NEXT_VERSION } from '../../../services/api'

import '../../../css/newEntry.css'

const EntryKey = props => {
    const {
        step,
        setStep,
        pratice,
        language,
        content,
        setContent,
        token,
        auth,
        setTranslator,
        expressions,
        setExpressions,
    } = React.useContext(LocationContext)

    const config = {
        headers: {
            authorization: `Bearer ${token}`,
        }
    }

    const [contentPerPage, setContentPerPage] = useState(20)
    const [currentPage, setCurrentPage] = useState(1)
    const [contentSplited, setContentSplited] = useState()
    const [loading, setLoading] = useState(false)
    // const [finalContent, setFinalContent] = useState([])

    const [expressionsSelected, setExpressionsSelected] = useState([])
    const [formsSelected, setFormsSelected] = useState([])

    useEffect(() => {
        const contentFormatted = content.filter((row, index, array) => {
            const elements = array.slice(index + 1)
            if (elements.every(element => element.toString() !== row.toString())) {
                row[1] = row[1] === undefined ? '' : row[1].trim()
                row[0] = row[0] === undefined ? '' : row[0].trim()
                return [row[0], row[1]]
            }

        })

        const contentFormattedWithoutSpace = contentFormatted.filter(data => data[0] !== '')
        setContent(contentFormattedWithoutSpace)
    }, [])

    useEffect(() => {
        const indexOfLastItem = currentPage * contentPerPage
        const indexOfFirstItem = indexOfLastItem - contentPerPage
        const contentSplited = content.slice(indexOfFirstItem, indexOfLastItem)
        const allSelectedExpressions = JSON.parse(localStorage.getItem('algetec-storage-expression')) || []

        setLoading(true)
        const contentWithInKeysFromDatabase = contentSplited.map(async (contentParam, index) => {

            // Validation if has the keyname's hash to valida
            const validateIfValueHasHash = contentParam[1].split('//').filter(data => data).map(data => data.trim())
            if (validateIfValueHasHash.length >= 2) {
                contentSplited[index][1] = validateIfValueHasHash[0]
            }
            const { data } = await axios.post(

                `${process.env.REACT_APP_API_ORIGIN}/keyname-by-name/language/${language.id}`, { name: contentParam[0] }, config)
            const contentIndex = content.find((item, index) => item[0] === contentParam[0])
            if (data.length > 0) {

                const versions = data[0].map(({ version }) => version)
                const maxVersion = versions.reduce((firstItem, secondItem) => Math.max(firstItem, secondItem))
                const maxValue = data[0].find(data => {
                    return Number(maxVersion) === Number(data.version)
                })

                data[0] = data[0].filter(dataParam => !!dataParam.value)

                const expressionHasExists = data[0].find(dataParam => decodeURIComponent(dataParam.value.trim()) === decodeURIComponent(contentParam[1]).trim())

                data[0].push({
                    version: data[1] + 1,
                    value: Boolean(expressionHasExists) ? '' : decodeURIComponent(contentParam[1]),
                    keyname: contentParam[0],
                    disable: false
                })
                contentSplited[index][1] = maxValue?.value
            }

            if (data.length === 0) {

                data[0] = []

                let valueDecode
                try {
                    valueDecode = decodeURIComponent(contentParam[1])
                } catch (err) {
                    if (err) {

                        valueDecode = contentParam[1]
                    }
                }

                data[0].push({
                    version: 1,
                    value: valueDecode,
                    keyname: contentParam[0],
                    disable: false
                })

            }

            contentSplited[index][2] = data[0]
            contentSplited[index][3] = content.indexOf(contentIndex)
            return contentParam
        })


        const formatPromise = async () => {
            setContentSplited([])
            const contentWithInKeysFromDatabaseWithoutPromise = await Promise.all(contentWithInKeysFromDatabase)
            contentWithInKeysFromDatabaseWithoutPromise.map((data, index) => {
                const contentFinded = formsSelected.find(formsSelectedParam => formsSelectedParam.key === data[0] && formsSelectedParam.value !== data[1])
                if (contentFinded) {
                    contentWithInKeysFromDatabaseWithoutPromise[index][1] = contentFinded.value
                }
                return
            })

            // const contentKeys = contentWithInKeysFromDatabaseWithoutPromise.map(data => data[2])

            // const contentKeyFormated = contentKeys.map(data => _.uniq(data, (item, key, codigo) => item?.value))
            // contentWithInKeysFromDatabaseWithoutPromise.map((data, index) => contentWithInKeysFromDatabaseWithoutPromise[index][2] = contentKeyFormated[index])

            setExpressionsSelected(allSelectedExpressions)
            setContentSplited(contentWithInKeysFromDatabaseWithoutPromise)
            setLoading(false)
        }
        formatPromise()
    }, [currentPage])

    const PostCreateToolbar = props => (
        <Toolbar {...props} >
            <Button
                label="Salvar Nova Versão"
                key='LocationInput'
                disabled={props.disabled}
                component={Link}
                onClick={handleSubmit}
            >
            </Button>
        </Toolbar>
    );

    const handleSubmit = async () => {

        setLoading(true)
        const forms = Array.from(document.querySelectorAll('[id^="form-"]'))
            .filter(form => form.querySelector('[id^="horns"]').checked)

        const itemsSelected = forms.map(form => {
            const value = form.querySelector('#value').value?.trim()
            const key = form.querySelector('#key').value?.trim()
            return { key, value }
        })

        let formFormated
        if (formsSelected.length > 0) {
            formFormated = itemsSelected?.map((data, index) => {
                const defaultItemSelected = formsSelected?.find((item, index) => data.key === item?.key)

                if (defaultItemSelected && defaultItemSelected?.value !== data?.value) {
                    defaultItemSelected.value = data?.value
                }
                return defaultItemSelected
            })
        }

        formFormated = [...formsSelected, ...itemsSelected]

        let formUniqueFormated
        if (!!formFormated) {
            formUniqueFormated = _.uniq(formFormated, (item, key, codigo) => item?.key);
        }

        const translator = document.querySelector("#translator").value
        setTranslator(translator)

        const data = {
            entry_version: null,
            finished_in: null,
            csv_file: null,
            fk_pratice: pratice?.id,
            fk_language: language?.id,
            responsible: translator,
            translates: []
        }

        const formValues = []

        for (let form of formUniqueFormated) {
            const initialValue = form?.value.trim() || ''
            const valuesFormated = initialValue.split('//').filter(data => data).map(data => data.trim())

            let valueWithoutHash = initialValue

            if (valuesFormated.length >= 2) {
                valueWithoutHash = valuesFormated[0]
            }
            const value = encodeURIComponent(valueWithoutHash)
            const key = encodeURIComponent(form?.key.trim())

            // console.debug(content.find(data => encodeURIComponent(data[0]) === key && encodeURIComponent(data[1]) === value)[2])
            try {
                const { data: { id } } = await axios.post(`${process.env.REACT_APP_API_ORIGIN}/keyname`, { name: key }, config)

                const { data: { forecast } } = await TRANSLATE_NEXT_VERSION.get({
                    fk_keyname: id,
                    fk_language: language?.id
                })

                const translateResponse = await CHECK_DUPLICATE_EXPRESSION.get({
                    fk_keyname: id,
                    fk_language: language?.id,
                    value: value
                })

                // console.debug(translateResponse.data.duplicate.id)
                const [duplicate] = translateResponse?.data.duplicate

                // Mounting expression object
                data.translates.push({
                    id: uuid(),
                    value,
                    version: duplicate ? duplicate?.version : forecast,
                    responsible: translator,
                    author: auth?.username,
                    fk_keyname: id,
                    fk_language: language?.id,
                    image_url: '',
                    save: !duplicate,
                    form: [key, value]
                })

                formValues.push([key, value])

            } catch (error) {

                // if (error) {
                // toast.error(`Ocorreu um erro durante o cadastro da tradução pertencente a chave: ${key}`)
                // }

                const { data: res } = await axios.get(`${process.env.REACT_APP_API_ORIGIN}/keyname-by-name/${key}`, config)
                const { id } = res

                const translateResponse = await CHECK_DUPLICATE_EXPRESSION.get({
                    fk_keyname: id,
                    fk_language: language?.id,
                    value: value
                })

                const { data: { forecast } } = await TRANSLATE_NEXT_VERSION.get({
                    fk_keyname: id,
                    fk_language: language?.id
                })

                const [duplicate] = translateResponse?.data.duplicate

                // Mounting expression object
                data.translates.push({
                    id: uuid(),
                    value,
                    version: duplicate ? duplicate?.version : forecast,
                    responsible: translator,
                    author: auth?.username,
                    fk_keyname: id,
                    fk_language: language?.id,
                    image_url: '',
                    save: !duplicate,
                    form: [key, value]
                })

                formValues.push([key, value])
            }

        }


        setLoading(false)
        setExpressions(data)
        setContent(formsSelected)
        setStep(step + 1)
    }

    const handleSelectAllExpressions = () => {

        const allItemChecked = Array.from(document.querySelectorAll('[id^="horns-"]')).map(checkbox => checkbox.checked)
        const [allItemCheckedFiltered] = allItemChecked.filter((data, index) => allItemChecked.indexOf(data) === index)
        if (allItemCheckedFiltered) {
            return
        }

        document.querySelectorAll('[id^="horns-"]').forEach(checkbox => {
            checkbox.checked = true
        })

        const allCheckboxSelected = content.map((item, index) => {
            return index
        })


        const forms = Array.from(document.querySelectorAll('[id^="form-"]'))
            .filter(form => form.querySelector('[id^="horns"]').checked)

        const itemsSelected = forms.map(form => {
            const value = form.querySelector('#value').value?.trim()
            const key = form.querySelector('#key').value?.trim()
            return { key, value }
        })

        const contentFormatedWithOnlyKeyAndValue = content.map((data, index) => {
            const teste = itemsSelected.find(itemsSelectedParam => itemsSelectedParam.key === data[0])
            if (teste) {
                content[index][0] = teste?.key
                content[index][1] = teste?.value
            }
            return { key: content[index][0], value: content[index][1] }
        })
        setFormsSelected(contentFormatedWithOnlyKeyAndValue)

        const oldExpressionsSelected = JSON.parse(localStorage.getItem('algetec-storage-expression')) || []
        if (!!oldExpressionsSelected) {
            const expressionsWithDuplicates = [...oldExpressionsSelected, ...allCheckboxSelected]
            const expressionFormatedWithoutDuplicates = expressionsWithDuplicates.filter((expressionParam, index) => {
                return expressionsWithDuplicates.indexOf(expressionParam) === index
            })
            return localStorage.setItem('algetec-storage-expression', JSON.stringify(expressionFormatedWithoutDuplicates))
        }
        return localStorage.setItem('algetec-storage-expression', JSON.stringify(allCheckboxSelected))
    }

    const handleUpdateVersionSelected = (version, indexFromContent, textAreaName) => {
        document.querySelector(`[name=${textAreaName}]`).disabled = true
        const valuesFromKeyname = content.find((data, index) => index === indexFromContent ? data : null)[2]


        const [values] = valuesFromKeyname.filter(data => Number(version) === Number(data.version))
        document.querySelector(`[name=${textAreaName}]`).disabled = values.disable

        const valuesFromKeynameFinded = valuesFromKeyname?.find(data => data.version === Number(version))
        if (valuesFromKeynameFinded) {
            valuesFromKeynameFinded.value = valuesFromKeynameFinded?.value === 'undefined' ? '' : valuesFromKeynameFinded?.value
        }
        document.querySelector(`[name=${textAreaName}]`).value = decodeURIComponent(valuesFromKeynameFinded?.value)

        const contentMapped = content.map(contentParam => {

            if (contentParam[0] == valuesFromKeynameFinded?.keyname) {
                return [contentParam[0], valuesFromKeynameFinded?.value, valuesFromKeyname]
            }

            return [...contentParam]
        })

        setContent(contentMapped)
    }

    const handleSelectAExpressions = (index) => {
        const oldExpressionsSelected = JSON.parse(localStorage.getItem('algetec-storage-expression')) || []

        const indexIsFinded = oldExpressionsSelected.find(expressionIndexParam => expressionIndexParam === Number(index))
        const key = document.getElementById(`form-${index}`).getElementsByTagName('textarea').key.value
        const value = document.getElementById(`form-${index}`).getElementsByTagName('textarea').value.value

        // finalContent.filter((finalContentParam, index) => finalContentParam[index]) === content[indexIsFinded])
        if (typeof indexIsFinded === 'number') {
            setFormsSelected(oldFormsSelected => oldFormsSelected.filter(formsSelectedParam => formsSelectedParam.key !== key))
            const indexFromExpression = oldExpressionsSelected.indexOf(Number(index))
            if (indexFromExpression > -1) {
                oldExpressionsSelected.splice(indexFromExpression, 1)
            }
            // const teste = finalContent.filter((data, index) => finalContent.indexOf(data.key) === index)

            // setFinalContent([...finalContent, { key, value }])
            setExpressionsSelected(oldExpressionsSelected)
            return localStorage.setItem('algetec-storage-expression', JSON.stringify(oldExpressionsSelected))
        }
        setFormsSelected(oldFormsSelected => [...oldFormsSelected, { key, value }])

        // const formSelected = document.querySelectorAll(`#form-${index}`).filter(form => form.querySelector('[id^="horns"]').checked)

        oldExpressionsSelected.push(index)
        setExpressionsSelected(oldExpressionsSelected)
        // setFormsSelected(oldFormsSelected => oldFormsSelected.filter(formsSelectedParam => formsSelectedParam.key !== key))
        //
        return localStorage.setItem('algetec-storage-expression', JSON.stringify(oldExpressionsSelected))
    }

    const handlePlusPage = () => {

        const forms = Array.from(document.querySelectorAll('[id^="form-"]'))
            .filter(form => form.querySelector('[id^="horns"]').checked)

        const itemsSelected = forms.map(form => {
            const value = form.querySelector('#value').value?.trim()
            const key = form.querySelector('#key').value?.trim()
            return { key, value }
        })

        let testeFormated
        if (formsSelected.length > 0) {
            testeFormated = itemsSelected?.map((data, index) => {
                const defaultItemSelected = formsSelected?.find((item, index) => data.key === item?.key)

                if (defaultItemSelected && defaultItemSelected?.value !== data?.value) {
                    defaultItemSelected.value = data?.value
                }
                return defaultItemSelected
            })
        }

        testeFormated = [...formsSelected, ...itemsSelected]
        if (!!testeFormated) {
            const itemsSelectedUniqueFormated = _.uniq(testeFormated, (item, key, codigo) => item?.key);
            setFormsSelected(itemsSelectedUniqueFormated)
        }
        setCurrentPage(currentPageParam => currentPageParam + 1)
    }

    const handleLessPage = () => {
        setCurrentPage(currentPageParam => currentPageParam - 1)
    }

    const handleUnselectAllExpressions = () => {

        document.querySelectorAll('[id^="horns-"]').forEach(checkbox => {
            checkbox.checked = false
        })
        setFormsSelected([])

        return localStorage.setItem('algetec-storage-expression', JSON.stringify([]))
    }

    const generateOptionList = (dataParam, selectId) => {
        let maxVersion
        const versions = dataParam.map(({ version }) => version)
        const latestVersion = versions.reduce((firstItem, secondItem) => Math.max(firstItem, secondItem))
        if (dataParam.length > 1) {
            const dataParamWithoutMaxVersion = versions.filter(data => Number(data) !== Number(latestVersion))
            maxVersion = dataParamWithoutMaxVersion.reduce((firstItem, secondItem) => Math.max(firstItem, secondItem))
        }

        // const maxVersion = dataParamWithoutMaxVersion.reduce((firstItemWithoutMaxVersion, secondItemWithoutMaxVersion) => Math.max(firstItemWithoutMaxVersion.version, secondItemWithoutMaxVersion.version))
        const options = dataParam.map(data => {
            return (
                <option
                    // key={Math.random()}
                    selected={data.version === maxVersion ? true : false}
                    value={data.version}
                >
                    {data.version}
                </option>
            )
        })


        // const actualForm = document.getElementById(`form-${selectId.replace('version-', '')}`)
        // if (actualForm) {
        //     actualForm[3].disabled = false
        // }

        return options
    }

    const verifyIfExpressionIsActive = (index) => {
        if (expressionsSelected.length > 0) {
            const hasSelected = expressionsSelected.find(expressionsSelectedParam => expressionsSelectedParam === index)
            const response = typeof hasSelected === 'number' ? true : false
            return response
        }
    }
    return (
        <div className="pai" style={{ position: 'relative' }}>
            <SimpleForm toolbar={<PostCreateToolbar disabled={loading} />}>
                <Title title="Localizações" />
                <div style={{ backgroundColor: 'white', width: 'auto', position: 'relative', padding: '2em' }}>
                    <div>
                        <p>Nome da Prática: {pratice?.name}</p>
                        <p>Versão da Prática: {pratice?.version}</p>
                        <p>Idioma: {language?.name}</p>
                        <p>Versão da Entrada: </p>
                        <p>página {currentPage} de {Number.parseInt(content.length / contentPerPage)}</p>

                        <input type="hidden" name="author" value={auth?.id}></input>
                        <div style={{ marginBottom: '1em' }}>
                            <button className="seleExp" type="button" onClick={handleSelectAllExpressions}>Selecionar todas as expressões</button>
                            <button className="desseExp" type="button" onClick={handleUnselectAllExpressions}>Desselecionar todas as expresões</button>
                            <button className="traduSele" type="button">Traduzir selecionados</button>
                        </div>

                        <TextInput source="translator" label="Tradutor" id="translator" />
                    </div>
                    {loading ?
                        <h1>Carregando</h1> :

                        contentSplited?.map((row, index) => {

                            return (
                                <form id={`form-${row[3]}`}>
                                    <div>
                                        <div class="box">
                                            <input
                                                class="check"
                                                type="checkbox"
                                                id={`horns-${row[3]}`}
                                                name="horns"
                                                checked={verifyIfExpressionIsActive(row[3])}
                                                onClick={() => handleSelectAExpressions(row[3])}
                                            />
                                            <label class="check1" for={`horns-${row[3]}`}>Incluir Expressão</label>
                                            <br />
                                            <label>Versão:</label>
                                            <select
                                                name={`version-${row[3]}`}
                                                onChange={event => handleUpdateVersionSelected(
                                                    event.target.value,
                                                    row[3],
                                                    `value-${row[3]}`
                                                )}
                                                id={`version-${row[3]}`}>
                                                {/* <option></option> */}
                                                {
                                                    row[2] && generateOptionList(row[2], `version-${row[3]}`)
                                                }
                                            </select>
                                            <br />
                                            <textarea
                                                class="text1"
                                                name="story"
                                                id="key"
                                                rows="10"
                                                cols="43"
                                                placeholder="chave"
                                            >
                                                {decodeURIComponent(row[0])}
                                            </textarea>
                                            <textarea
                                                class="text2"
                                                name={`value-${row[3]}`}
                                                id="value"
                                                rows="10"
                                                disabled={row[2].length === 1 ? false : true}
                                                cols="43"
                                                placeholder="valor"
                                            >
                                                {decodeURIComponent(row[1])}
                                            </textarea>
                                        </div>
                                    </div>
                                </form>
                            )
                        })
                    }


                    <article style={{ display: 'flex', justifyContent: 'space-between' }}>
                        {
                            loading ? <></> :
                                <>
                                    <div>
                                        <MaterialUIButton
                                            variant="contained"
                                            color="primary"
                                            disabled={currentPage === 1 ? true : false}
                                            onClick={handleLessPage}>
                                            página anterior</MaterialUIButton>

                                        <MaterialUIButton
                                            variant="contained"
                                            color="primary"
                                            style={{ marginLeft: 20 }}
                                            disabled={currentPage === Number.parseInt(content.length / contentPerPage) ?
                                                true : false
                                            }
                                            onClick={handlePlusPage}>
                                            proxima página</MaterialUIButton>
                                    </div>

                                    <p style={{ fontWeight: 'bolder', textTransform: 'uppercase' }}>página {currentPage} de {Number.parseInt(content.length / contentPerPage) || 1}</p>
                                </>

                        }
                    </article>
                </div>

            </SimpleForm>
            <ToastContainer />
        </div>
    );

}

export default EntryKey
