import * as React from "react";
import '../../../css/locationinput.css';
import {
    SimpleForm,
    ReferenceInput,
    SelectInput,
    Toolbar,
    Button,
    Link,
} from 'react-admin';
import { useHistory } from "react-router-dom";

const PostCreateToolbar = props => (
    <Toolbar {...props} >
        <Button
            label="Nova Versão"
            key='Localizations'
            component={Link}
            to={{
                pathname: "/Localizations",
            }}
        >
        </Button>
    </Toolbar>
);

export const LICreate = props => {

    const history = useHistory()
    React.useEffect(() => {
        const userAuthenticated = localStorage.getItem('auth')
        if (!userAuthenticated) {
            history.push('/login')
        }
    })
    return (
        <div className="pai" style={{ position: 'relative' }}>
            <SimpleForm toolbar={<PostCreateToolbar />}>
                <div style={{ backgroundColor: 'white', width: 'auto', position: 'relative' }}>
                    <div>
                        <ReferenceInput label="Idioma" source="fk_language" reference="Language" allowEmpty>
                            <SelectInput optionText="name" style={{ width: '30%' }} />
                        </ReferenceInput>
                        <ReferenceInput label="Versão da entrada" source="fk_language" reference="Language" allowEmpty>
                            <SelectInput optionText="name" style={{ width: '30%' }} />
                        </ReferenceInput>
                        <br />
                        <ReferenceInput label="Versão da Prática" source="fk_language" reference="Language" allowEmpty>
                            <SelectInput optionText="name" style={{ width: '30%' }} />
                        </ReferenceInput>
                    </div>
                    <form>
                        <div>
                            <p>Nome da Prática:</p>
                            <p>Tradutor:</p>
                            <p>Revisor:</p>
                            <p>Data da Solicitação:</p>
                            <p>Data da Conclusão</p>
                            <h3>Conteúdo</h3>
                            <textarea class="area1" name="story" rows="35" cols="93">
                                It was a dark and stormy night...
                            </textarea>
                        </div>
                    </form>
                </div>
            </SimpleForm>
        </div>
    );
}
