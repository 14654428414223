import * as React from "react";
import { useMediaQuery } from '@material-ui/core';
import ReCAPTCHA from "react-google-recaptcha";
import {
    List,
    Datagrid,
    TextField,
    NumberField,
    EditButton,
    Edit,
    Show,
    SimpleShowLayout,
    Create,
    Filter,
    SimpleForm,
    SimpleList,
    ReferenceInput,
    SelectInput,
    TextInput,
    ShowButton,
    NumberInput,
    EmailField,
    PasswordInput,
    required,
    email,
    ReferenceField,
    AutocompleteInput,
} from 'react-admin';


const UserFilter = (props) => (
    <Filter {...props}>
       <ReferenceInput label="Buscar" source="q" reference="User" alwaysOn allowEmpty emptyValue='' resettable>
        <AutocompleteInput optionText="username" />
</ReferenceInput>
        <ReferenceInput label="User" source="userId" reference="User" allowEmpty>
            <SelectInput optionText="cargo" />
        </ReferenceInput>
    </Filter>
);

export const UserList = props => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
        <List filters={<UserFilter />} {...props}>
            {isSmall ? (
                <SimpleList
                    primaryText={record => record.title}
                    secondaryText={record => `${record.views} views`}
                    tertiaryText={record => new Date(record.published_at).toLocaleDateString()}
                />
            ) : (
                    <Datagrid rowClick="edit">
                        <TextField source="id" />
                        <TextField source="username" />
                        <EmailField source="email" />
                        <ReferenceField source="fk_role" reference="Role">
                          <TextField source="name" />
                        </ReferenceField>
                        <EditButton />
                        <ShowButton />
                    </Datagrid>
                )}
        </List>
    );
}
export const UserEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <TextField source="id" />
            <TextInput source="username" fullWidth />
            <TextInput source="email" fullWidth />
            <NumberInput source="fk_role" fullWidth />
            <ReferenceInput source="fk_role" reference="Role" allowEmpty validate={validateFirstName}>
            <SelectInput optionText="name" />
        </ReferenceInput>
        </SimpleForm>
    </Edit>
)

const validateFirstName = [required()];
const validateEmail = [email(),required()];

function validate(){
    const password = UserCreate.password.value;
    const valipass = UserCreate.valipass.value;

    if (password===valipass) {
        alert('senhas diferentes');
         UserCreate.valipass.focus();
        return false;
    }
}

function onChange(value) {
    console.log("Captcha value:", value);
  }

export const UserCreate = props => (
    <Create {...props}>
        <SimpleForm name="userform">
            <TextInput source="username" fullWidth validate={validateFirstName} />
            <TextInput source="email" fullWidth validate={validateEmail} />
            <PasswordInput name="password" source="password" fullWidth validate={validateFirstName} />
            <PasswordInput name="valipass" source="valipass" fullWidth validate={validateFirstName} onChange={validate} />
            <ReferenceInput source="fk_role" reference="Role" allowEmpty validate={validateFirstName}>
            <SelectInput optionText="name" />
        </ReferenceInput>
        <ReCAPTCHA
      sitekey="6Lf-K4AbAAAAAIN6xU5ze77h9Y5PSFmi1ox7Mrk8"
      onChange={onChange}
    />
        </SimpleForm>
    </Create>
)

export const UserShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="id" />
            <TextField source="username" />
            <EmailField source="email" />
            <NumberField source="fk_role" />
        </SimpleShowLayout>
    </Show>
);
