import { Button } from '@material-ui/core'
import { GetApp } from '@material-ui/icons'
import React from 'react'
import { CSVLink } from 'react-csv'

function CsvButtonDownload(props) {

  return props.data ? (
    <CSVLink data={props?.data} enclosingCharacter={``} separator=";">
      <Button
        variant="contained"
        color="default"
        startIcon={<GetApp />}
      >{props?.label}</Button>
    </CSVLink>
  )
    : <></>
}

export default CsvButtonDownload
