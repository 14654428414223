import * as React from "react";
import { useMediaQuery } from '@material-ui/core';
import {
    Container,
    Grid,
    Paper,
    InputLabel,
    FormControl,
    Input,
    Button,
    Box
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import XlsLocalization from '../../../components/exporter/XlsLocalization'
import CsvButtonDownload from '../../../components/exporter/CsvButtonDownload'
import { AWS, LOCALIZATION } from '../../../services/api'
import { useHistory } from 'react-router-dom'
import '../../../css/locationinput.css';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
//import { ImportButton } from "react-admin-import-csv";
import jsonExport from 'jsonexport/dist';
import axios from 'axios'
import { Fragment } from 'react';
import {
    List,
    TextField,
    Datagrid,
    EditButton,
    ReferenceInput,
    SelectInput,
    ReferenceField,
    Edit,
    Create,
    Filter,
    SimpleForm,
    SimpleList,
    TextInput,
    ListButton,
    useListContext,
    TopToolbar,
    CreateButton,
    sanitizeListRestProps,
    downloadCSV,
    BulkDeleteButton,
    BulkExportButton,
    AutocompleteInput,
    ExportButton,
    ShowButton,
    SimpleShowLayout,
    Show,
    FileField,
    useField,
} from 'react-admin';
import { cloneElement } from 'react';
import { useParams } from "react-router-dom";
import * as XLSX from 'xlsx'


import EntryVersion from './../../../components/translation/localization/EntryVersion';
import EntryContent from './../../../components/translation/localization/EntryContent'
import EntryKey from './../../../components/translation/localization/EntryKey'
import EntryTranslation from './../../../components/translation/localization/EntryTranslation'
import ChoosePratice from '../../../components/translation/localization/ChoosePratice'
import LocationContext from '../../../contexts/LocalizationContext'

const ListActions = (props) => {
    const {
        className,
        exporter,
        filters,
        maxResults,
        ...rest
    } = props;
    const {
        resource,
        displayedFilters,
        filterValues,
        basePath,
    } = useListContext();
    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            {filters && cloneElement(filters, {
                resource,
                displayedFilters,
                filterValues,
                context: 'button',
            })}
            <ExportButton />
            <CreateButton basePath={basePath} label="Nova Localização" />
        </TopToolbar>
    );
};


const TranslFilter = (props) => (
    <Filter {...props}>
        <ReferenceInput label="Buscar" source="q" reference="Localizations" alwaysOn allowEmpty emptyValue='' resettable>
            <AutocompleteInput optionText="updated_at" />
        </ReferenceInput>
        <ReferenceInput source="name" label="Prática" reference="Localizations" allowEmpty>
            <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput source="version" label="Versão da Prática" reference="Localizations" allowEmpty>
            <SelectInput optionText="version" />
        </ReferenceInput>
    </Filter>
);

const exporter = Localizations => {
    const postsForExport = Localizations.map(Localizations => {
        const { backlinks, created_at, csv_file, finished_in, entry_version, ...postForExport } = Localizations;
        return postForExport;
    });
    jsonExport(postsForExport, {
        headers: ['id', 'updated_at'],
        rowDelimiter: ';'
    }, (err, csv) => {
        downloadCSV(csv, 'Localizations');
    });
};

const PostBulkActionButtons = props => (
    <Fragment>
        <BulkExportButton {...props} />
        <BulkDeleteButton {...props} />
    </Fragment>
);

export const LocalList = props => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
        <List
            bulkActionButtons={<PostBulkActionButtons />}
            exporter={exporter}
            actions={<ListActions />}
            filters={<TranslFilter />}
            {...props}>
            {isSmall ? (
                <SimpleList
                    primaryText={record => record.title}
                    secondaryText={record => `${record.views} views`}
                    tertiaryText={record => new Date(record.published_at).toLocaleDateString()}
                />
            ) : (
                <Datagrid>
                    <ReferenceField
                        source="pratice_fk"
                        reference="Pratice"
                        label="Prática"
                        linkType={false}
                    >
                        <TextField source="name" />
                    </ReferenceField>
                    <ReferenceField
                        source="pratice_fk"
                        reference="Pratice"
                        label="Versão da prática"
                        linkType={false}
                    >
                        <TextField source="version" />
                    </ReferenceField>
                    <TextField source="responsible" label="resources.Localizations.fields.responsible" />
                    <ReferenceField
                        source="fk_language"
                        reference="Languages"
                        label="resources.Languages.fields.name"
                        linkType={false}
                    >
                        <TextField source="name" />
                    </ReferenceField>
                    <TextField source="updated_at" />
                    <ShowButton />
                </Datagrid>
            )}
        </List>
    );
}

const useStyles = makeStyles({
    root: {
        flexGrow: 1
    },
    paper: {
        padding: '1.5em'
    },
    select: {
        width: '100%'
    },
    contentField: {
        width: '100%',
        height: '24vh',
        borderBox: 0,
        borderColor: 'transparent',
        resize: 'none',
    }
})

export const LocalShow = (props) => {
    const classes = useStyles()
    const history = useHistory()
    const [localization, setLocalization] = React.useState()
    const [localizationCSVContent, setLocalizationCSVContent] = React.useState()
    const [loading, setLoading] = React.useState(false)
    const [data, setData] = React.useState(props?.data)
    const { id } = useParams()

    React.useEffect(() => {

        const auth = localStorage.getItem('auth')
        const { token, user } = JSON.parse(auth)

        async function findData() {

            const config = {
                headers: {
                    authorization: `Bearer ${token}`,
                }
            }
            const response = await axios.get(`${process.env.REACT_APP_API_ORIGIN}/localizations/${id}`, config)
            return setLocalization(response?.data)
        }

        findData()
    }, [])

    const {
        content,
        //localization
    } = React.useContext(LocationContext)


    const [allowedFileTypes,] = React.useState([
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'text/csv'
    ])

    const handleImportationCsv = ({ target }) => {
        const file = target.files[0]
        handleImportCsv(file)
    }

    const handleImportCsv = (file) => {
        const formData = new FormData()
        var reader = new FileReader();

        setLoading(true)
        reader.onload = async function () {

            const csvFile = new File(
                [reader.result],
                'file.csv',
                { type: 'text/csv' }
            )

            formData.append('file', csvFile)

            if (localization?.id) {
                try {
                    const hasUpdated = await LOCALIZATION.putFromFile(localization.id, formData)

                    if (hasUpdated) {
                        history.push('Localizations')
                    }
                } catch (error) {
                    setLoading(false)
                    console.debug(error.message)
                    toast.error(`Erro, verifique o console.`)
                }

            }
        };

        reader.readAsBinaryString(file);
    }

    const handleImportationXlsx = ({ target }) => {
        const file = target.files[0]
        handleImportExcel(file)
    }


    const handleImportExcel = (file) => {
        const reader = new FileReader();
        const formData = new FormData()

        reader.onload = async () => {
            const bstr = reader.result
            const wb = XLSX.read(bstr, { type: 'binary' })
            const wsname = wb.SheetNames[0]
            const ws = wb.Sheets[wsname]
            const data = XLSX.utils.sheet_to_csv(ws, { header: 1, FS: ";" })
            const xlsfile = new File(
                [data],
                'file.xlsx',
                {}
            )

            formData.append('file', xlsfile)

            if (localization?.id) {
                try {
                    const hasUpdated = await LOCALIZATION.putFromFile(localization.id, formData)

                    if (hasUpdated) {
                        history.push('Localizations')
                    }
                } catch (error) {
                    setLoading(false)
                    console.debug(error.message)
                    toast.error(`Erro, verifique o console.`)
                }

            }
        };

        reader.readAsBinaryString(file);
    };
    return (
        localization ? (
            <>
                <Show {...props} >
                    <SimpleShowLayout>
                        <Container maxWidth className={classes.root}>
                            <Paper className={classes.paper}>
                                <Grid container spacing={1}>
                                    <Grid md item>
                                        <p>Nome da prática:
                                            <ReferenceField
                                                source="pratice_fk"
                                                reference="Pratice"
                                                label="resources.Pratice.fields.name"
                                                linkType={false}
                                            >
                                                <TextField source="name"></TextField>
                                            </ReferenceField>
                                        </p>
                                        <p>Tradutor:
                                            <TextField source="responsible"></TextField>
                                        </p>
                                        <p>Revisor: </p>
                                        <p>Data da conclusão:
                                            <TextField source="updated_at" />
                                        </p>
                                    </Grid>
                                    <Grid md item>
                                        <Grid container spacing={2} >
                                            <Grid item md={6}>
                                                <InputLabel>Idioma</InputLabel>
                                                <ReferenceField
                                                    source="fk_language"
                                                    reference="Languages"
                                                    label="resources.Translation.fields.fk_language"
                                                    linkType={false}
                                                >
                                                    <TextField source="name"></TextField>
                                                </ReferenceField>
                                            </Grid>
                                            <Grid item md={6}>
                                                <FormControl variant="filled" className={classes.select}>
                                                    <InputLabel>Versão de entrada</InputLabel>
                                                    <Input value='' disabled />
                                                </FormControl>
                                            </Grid>
                                            <Grid item md={6}>
                                                <InputLabel>Versão da prática</InputLabel>
                                                <ReferenceField
                                                    source="pratice_fk"
                                                    reference="Pratice"
                                                    label="resources.Pratice.fields.version"
                                                    linkType={false}
                                                >
                                                    <TextField source="version"></TextField>
                                                </ReferenceField>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid container>
                                    {
                                        loading ? (
                                            <h2>Atualizando</h2>
                                        ) : (
                                            <Grid item md>
                                                <h3>Conteúdo</h3>
                                                <textarea
                                                    className={classes.contentField}
                                                    multiline
                                                    readOnly
                                                    value={localization?.content}
                                                >
                                                </textarea>
                                            </Grid>
                                        )
                                    }

                                </Grid>

                                <Box marginTop={2}>
                                    <Grid container spacing={2}>
                                        <Grid item>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                disabled={loading}
                                                onClick={() => history.push('Localizations')}
                                            >Retornar</Button>
                                        </Grid>
                                        <Grid item>
                                            {localization && !loading && <XlsLocalization
                                                label="Exportar xlsx"
                                                data={localization?.content}
                                            />}
                                        </Grid>
                                        <Grid item>
                                            {localization && !loading && <CsvButtonDownload
                                                label="Exportar csv"
                                                data={localization?.content}
                                                fileExtension="csv"
                                            />}

                                            {loading ? <></> : (
                                                <>
                                                    <label className="import" for="import_csv">Importar CSV
                                                        <input
                                                            className="fileImport"
                                                            type="file"
                                                            disabled={loading}
                                                            onChange={handleImportationCsv}
                                                            id="import_csv"
                                                            name="import_csv"
                                                            value={localizationCSVContent}
                                                        ></input>
                                                    </label>

                                                    <label className="import" for="import_xlsx">Importar XLSX
                                                        <input
                                                            className="fileImport"
                                                            type="file"
                                                            onChange={handleImportationXlsx}
                                                            id="import_xlsx"
                                                            name="import_xlsx"
                                                            accept={allowedFileTypes.toString(',')}
                                                        ></input>
                                                    </label>
                                                </>
                                            )}

                                        </Grid>
                                    </Grid>
                                </Box>
                            </Paper>
                        </Container>
                    </SimpleShowLayout>
                </Show>
                <ToastContainer />

            </>
        )
            : <></>
    );
}

export const LocalCreate = props => {
    const [step, setStep] = React.useState(0)
    const [loading, setLoading] = React.useState(false)
    const [pratice, setPratice] = React.useState()
    const [content, setContent] = React.useState()
    const [auth, setAuth] = React.useState()
    const [token, setToken] = React.useState()
    const [language, setLanguage] = React.useState()
    const [translator, setTranslator] = React.useState()
    const [expressions, setExpressions] = React.useState()
    const [localization, setLocalization] = React.useState({})

    React.useEffect(() => {
        const auth = localStorage.getItem('auth')
        const { token, user } = JSON.parse(auth)

        setToken(token)
        setAuth(user)
    }, [])

    const contextProvider = {
        step,
        setStep,
        setLoading,
        loading,
        pratice,
        setPratice,
        content,
        setContent,
        auth,
        setAuth,
        token,
        language,
        setLanguage,
        translator,
        setTranslator,
        setExpressions,
        expressions,
        localization,
        setLocalization
    }

    const screens = [
        <ChoosePratice {...props} />,
        <EntryContent {...props} />,
        <EntryKey {...props} />,
        <EntryTranslation {...props} />,
        <EntryVersion {...props} />
    ]

    return (
        <LocationContext.Provider value={contextProvider}>
            {screens[step]}
        </LocationContext.Provider>
    )
}
