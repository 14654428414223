import * as React from "react";
import '../../../css/newTranslation.css';
import {
  SimpleForm,
  Filter,
  TextInput,
  ReferenceInput,
  SelectInput,
  TextField
} from 'react-admin';
import { useHistory } from 'react-router-dom';
import axios from "axios";
import { Input } from '@material-ui/core';

/*const ListActions = (props) => {
    const {
        className,
        exporter,
        filters,
        maxResults,
        ...rest
    } = props;
    const {
        resource,
        displayedFilters,
        filterValues,
    } = useListContext();
    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            {filters && cloneElement(filters, {
                resource,
                displayedFilters,
                filterValues,
                context: 'button',
            })}
        </TopToolbar>
    );
};*/


const TranslFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Buscar" source="q" alwaysOn />
  </Filter>
);

/*const exporter = Translation => {
    const postsForExport = Translation.map(Translation => {
        const { backlinks, created_at, updated_at, ...postForExport } = Translation;
        return postForExport;
    });
    jsonExport(postsForExport, {
        headers: ['id', 'key', 'translation', 'language', 'version', 'translator', 'updated_at'],
        rowDelimiter:';'
    }, (err, csv) => {
        downloadCSV(csv, 'Translation');
    });
};*/

class Upload extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      file: null
    }
    this.handleChange = this.handleChange.bind(this)
  }
  handleChange(event) {
    this.setState({
      file: URL.createObjectURL(event.target.files[0])
    })
  }

  render() {
    return (
      <div class="imgpai">
        <img class="img2" alt="exibição da imagem a ser enviada" src={this.state.file} />
        <input class="img" type="file" onChange={this.handleChange} />
      </div>
    );
  }
}

export const NTCreate = props => {

  const [keyname, setKeyname] = React.useState()
  const history = useHistory()
  const [date, setDate] = React.useState()
  const [value, setValue] = React.useState()
  const [version, setVersion] = React.useState()

  const [autor, setAutor] = React.useState()


  React.useEffect(() => {
    const userAuthenticated = localStorage.getItem('auth')
    if (!userAuthenticated) {
      history.push('/login')
    }
  })

  React.useEffect(async () => {
    const key = sessionStorage.getItem('translation/key')
    const auth = localStorage.getItem('auth')
    const { user } = JSON.parse(auth)
    const { token } = JSON.parse(auth)
    let date = new Date().toLocaleString('pt-BR');
    setAutor(user.username);
    setDate(date)
    if (!key) {
      history.goBack()
    }

    await axios.post(`${process.env.REACT_APP_API_ORIGIN}/keyname`, { name: key }, {
      headers: {
        authorization: `Bearer ${token}`,
      }
    })
      .then(({ data }) => setKeyname(data))
      .catch(async () => {
        await axios.get(`${process.env.REACT_APP_API_ORIGIN}/keyname?name=${key}`, {
          headers: {
            authorization: `Bearer ${token}`,
          }
        }).then((keyname) => setKeyname(keyname.data[0]))
      })

  }, [])

  const handleChangeLanguage = ({ target }) => {
    const { value } = target
    const auth = localStorage.getItem('auth')
    const { user } = JSON.parse(auth)
    const { token } = JSON.parse(auth)
    axios.get(`${process.env.REACT_APP_API_ORIGIN}/translates/language/${value}/keyname/${keyname.id}/versions`, {
      headers: {
        authorization: `Bearer ${token}`,
      }
    }).then(({ data }) => setVersion(data.forecast))
  }

  const handleValue = ({ target }) => setValue(target.value);

  function handleSubmitForm(event) {
    const auth = localStorage.getItem('auth')
    const { user } = JSON.parse(auth)
    const { token } = JSON.parse(auth)
    let data = {
      fk_keyname: keyname.id,
      responsible: event.tradutor,
      value: value,
      author: autor,
      version: version,
      fk_language: event.fk_language
    }

    axios.post(`${process.env.REACT_APP_API_ORIGIN}/translation/`, data, {
      headers: {
        authorization: `Bearer ${token}`,
      }
    }).then(function (response) {
      history.push('/Translation')
    })

  }

  return (
    <div className="pai" style={{ position: 'relative' }}>
      <SimpleForm save={handleSubmitForm}>
        <div style={{ backgroundColor: 'white', width: 'auto', position: 'relative', padding: '2em' }}>
          <form onSubmit={handleSubmitForm}>
            <div>
              <ReferenceInput
                label="Idioma"
                source="fk_language"
                reference="Languages"
                allowEmpty
                onChange={handleChangeLanguage}
              >
                <SelectInput optionText="name" style={{ width: '50%' }} />
              </ReferenceInput>
              <br />

              <TextInput label="Tradutor" source="tradutor" style={{ width: '50%' }} />

            </div>
            <div>
              <p>Autor: {autor}</p>
              <p>Criação: {date}</p>
              <p>
                <Input placeholder="Versão" value={`v${version}`} disabled style={{ width: '50%' }} />
              </p>
              <textarea disabled class="area1" name="story" rows="10" cols="43" value={keyname && keyname.name}>

              </textarea>
              <textarea class="area2" source="value" rows="10" cols="43" placeholder="Tradução" onChange={handleValue}>
              </textarea>
            </div>
            <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
            <div id="upload">
              <Upload />
            </div>

          </form>
        </div>
      </SimpleForm>
    </div>
  );
}
