import React from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from "@fullcalendar/interaction";
import Modal from '@material-ui/core/Modal';
import axios from 'axios';


const Calendar = () => {
    const auth = localStorage.getItem('auth');
    const { token } = JSON.parse(auth);
    const [events, setEvents] = React.useState([])
    const [pratices, setPratices] = React.useState([])


    const formatedDate = (date) => {
        let dateDeadline = new Date(date?.deadline);

        let month = dateDeadline.getMonth() + 1 < 10 ? `0${dateDeadline.getMonth() + 1}` : `${dateDeadline.getMonth() + 1}`
        let day  = dateDeadline.getDate() < 10 ? `0${dateDeadline.getDate()}` : `${dateDeadline.getDate()}`
        let dateFormated = (`${dateDeadline.getFullYear()}-${month}-${day}`) ;
        return dateFormated

    }
    const findEvents = async () => {
        const {data} = await axios.get(`${process.env.REACT_APP_API_ORIGIN}/pratice`, {
            headers: {
              authorization: `Bearer ${token}`
        }})


        const eventsVariable = data.map(dataParam => {
            const eventDate = formatedDate(dataParam)
            return {
                id: dataParam.id,
                start: eventDate,
                title: dataParam.name
            }
        })

        setEvents(eventsVariable)
        setPratices(data)
    }


    function handleDateClick(arg) {

        const praticesInfo = pratices.find(pratice => {
            const praticeDate = formatedDate(pratice)
            return praticeDate == arg.dateStr
        })

        if(praticesInfo){
            alert(`
            id: ${praticesInfo.id}
            pratice: ${praticesInfo.name}
            deadline: ${arg.dateStr}
            `)
        }
    }


    React.useEffect(() => {
        findEvents()
    },[])

    return (
        <div>
        <select onChange={() => {}}
            name="select">
            <option >Filtre seus eventos...</option>
            <option value="pratice">Práticas</option>
            <option value="request">Requisições</option>
        </select>
        <FullCalendar
            plugins={[dayGridPlugin, interactionPlugin]}
            locale='pt-br'
            locales='allLocales'
            dateClick={handleDateClick}
            initialView="dayGridMonth"
            editable={true}
            eventLimit={false}
            events={events}
            eventColor="blue"
        />
        </div>
    )
}

export default Calendar
