import * as React from 'react';

const LocalizationContext = React.createContext({
  loading: true,
  setLoading: () => {},
  setStep: () => {},
  step: 0,
  pratice: {},
  setPratice: () => {},
  content: [],
  setContent: () => {},
  auth: {},
  token: '',
  language: '',
  setLanguage: () => {},
  translator: '',
  setTranslator: () => {},
  expressions: {},
  setExpressions: () => {},
  localization: {},
  setLocalization: () => {}
})

export default LocalizationContext