import React from 'react'
import { Toolbar } from 'react-admin';
import { Button } from '@material-ui/core'
import { Save } from '@material-ui/icons'

const PostCreateToolbar = props => (
  <Toolbar {...props} >
    { props.disabled || (
      <Button
        variant="contained"
        color="primary"
        size="medium"
        startIcon={<Save />}
        disabled
      >
        Avançar
      </Button>        
    ) }

    { !props.disabled || (
      <Button
        variant="contained"
        color="primary"
        size="medium"
        startIcon={<Save />}
        onClick={props.save}
      >
        Avançar
      </Button>
    ) }

  </Toolbar>
);

export default PostCreateToolbar