import { useProfile } from '../pages/profile'
import { Settings } from '@material-ui/icons'
import {
  UserMenu,
  MenuItemLink,
  useTranslate
} from 'react-admin'

const MyUserMenu = (props) => {
  const { profileVersion } = useProfile()
  const translate = useTranslate()

  return (
    <UserMenu key={profileVersion} {...props}>
      <MenuItemLink
        to='/profile'
        primaryText={translate('profile.name')}
        leftIcon={<Settings />}
      />
    </UserMenu>
  )
}

export default MyUserMenu
