const en = {
    Dashboard:{
      name: 'Dashboard |||| Dashboards',
    },
    resources: {
      Request: {
        name: 'Request |||| Requests',
        fields: {
          number: 'number',
          deadline: 'deadline',
          status: 'status',
          fk_clients:'Client',
        }
      },
      Pratice: {
        name: 'Pratice |||| Pratices',
        fields: {
          name: 'name',
          deadline:'deadline',
          status: 'status',
          justification: 'justification',
          fk_request:'associated order',
          fk_skills:'Skill',
          fk_stakeholder:'Stakeholder',
        }
      },
      Infra: {
        name: 'Infra |||| Infras',
    },
    Demand: {
        name: 'Demand |||| Demands',
        fields:{
          Create:'quick creation',
        }
    },
    demandquality:{
      name:'Quality Demand |||| Quality Demands',
      fields:{
        practices_id:'Pratice',
        deadline:'Deadline',
        description:'Description',
        weight:'Weight',
        status:'Status',
        timing:'Timing',
        qa_id:'Reviewer',
        category_demands_qa:'Quality Category'
      }
    },
    demandmodeling:{
      name:'Modeling Demand |||| Modeling Demands',
      fields:{
        fk_pratice:'Pratice',
        deadline:'Deadline',
        asset_name:'Responsible',
        weight:'Weight',
        referencie:'Referencie',
        status:'Status',
        timing:'Timing',
        fk_shaper:'shaper',
        fk_category_modeling:'Modeling Category',
      }
    },
    demandsdev:{
      name:'Dev Demand |||| Dev Demands',
      fields: {
        deadline:'Deadline',
        description:'Description',
        weight:'Weight',
        fk_pratice:'Pratice',
        fk_dev:'Dev'
      }
    },
    'category/demandmodeling':{
        name:'Modeling Demand Category |||| Modeling Demands Category',
        fields:{
          name:'category name',
        }
    },
    'category/demanddev':{
      name:'Dev Demand Category |||| Dev Demands Category',
      fields:{
        name:'category name',
      }
    },
    'category/demandquality':{
      name:'quality demand category |||| quality demands category',
      fields:{
        name:'category name',
      }
    },
    Client: {
      name: 'Client |||| Clients',
      fields:{
          name:'Client name',
          cpf_cnpj:'CPF/CNPJ'
      }
      },
     User:{
         name:'User |||| Users',
         fields:{
             username:'Username',
             email:'E-mail',
             fk_role:'Role'
         }
  },
      Bug:{
        name:'BUG |||| BUGS',
        fields:{
          subject:'Subject',
          description:'Description'
        }
  },

  StakeHolder:{
      name:'Stakeholder |||| Stakeholders',
      fields:{
          name:'Name',
          email:'E-mail',
          cpf:'CPF',
          fk_clients:'Client',
          fk_category_stakeholder:'Category Stakeholder',
      }
  },
  Translation:{
    name:'Translation |||| Translations',
    fields:{
        name:'Portuguese',
        english:'English',
        spanish:'Spanish',
    }
},

    }
  }

  export default en
