import * as React from "react";
import { useMediaQuery } from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import {
    List,
    Datagrid,
    TextField,
    EditButton,
    Edit,
    Create,
    Filter,
    SimpleForm,
    SimpleList,
    TextInput,
    ListButton,
} from 'react-admin';

const CDModelFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Buscar" source="q" alwaysOn />
    </Filter>
);

export const CDModelList = props => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
    <List filters={<CDModelFilter />} {...props}>
            {isSmall ? (
        <SimpleList
            primaryText={record => record.title}
            secondaryText={record => `${record.views} views`}
            tertiaryText={record => new Date(record.published_at).toLocaleDateString()}
        />
        ) : (
        <Datagrid>
        <TextField source="id"/>
        <TextField source="name"/>
        <EditButton />
        </Datagrid>
        )}
    </List>
);
        }

const CDModelTitle = ({ record }) => {
        return <span>Requests </span>;
    };

export const CDModelEdit = props => (
    <Edit {...props}>
        <SimpleForm>
        <TextInput source="name" fullWidth/>
            <ListButton label="Back" icon={<ChevronLeft />}/>
        </SimpleForm>
    </Edit>
);

export const CDModelCreate = props => (
        <Create title={<CDModelTitle />} {...props}>
          <SimpleForm>
          <TextInput source="name" fullWidth/>
          </SimpleForm>
       </Create>
    );
