import * as React from "react";
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import {
    Button,
    TextField,
    Paper,
    Grid
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';

const buttonStyle = {
    width: 'auto',
    height: '37px',
    fontsize: '20px',
    fontWeight: 'bold',
    backgroundColor: 'blue',
    fontSize: '10px',
    color: '#ddd',
    borderRadius: '6px',
    display: 'flex',
    justifyContent: 'center',
};

const buttonClosedInputStyle = {
    ...buttonStyle,
    backgroundColor: 'red',
    marginLeft: '90%',
}
const useStyles = makeStyles((theme) => ({
    paper: {
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        height: theme.spacing(16),
        width: '100%',
        fontSize: '1.3em'
    },
    labelParagraph: {
        textAlign: 'center'
    },
    grid: {
        marginBottom: '1em'
    }
}));

export const TemplateCreate = props => {
    const history = useHistory()
    React.useEffect(() => {
        const userAuthenticated = localStorage.getItem('auth')
        if (!userAuthenticated) {
            history.push('/login')
        }
    })
    const [markdownFile, setmarkdownFile] = React.useState();
    const [extFilesSupported, setExtFilesSupported] = React.useState([
        'zip'
    ])
    const [background, setBackGround] = React.useState();
    const [nome_da_ua_capa, setnome_da_ua_capa] = React.useState();
    const [nome_da_ua_barra, setnome_da_ua_barra] = React.useState();
    const [optionalInputs, setOptionalInputs] = React.useState([])
    //  const [presentation, setPresentation] = React.useState();
    //  const [posttest, setPosttest] = React.useState();
    //  const [pretest, setPretest] = React.useState();
    const [laboratorio, setLaboratorio] = React.useState();
    const [caparoteiro, setCapaRoteiro] = React.useState();
    const [roteiroApresentacao, setRoteiroApresentacao] = React.useState();
    const [capasumario, setCapaSumario] = React.useState();
    const [sumarioTeorico, setSumarioTeorico] = React.useState();
    const [actualInputCount, setActualInputCount] = React.useState([]);
    const [actualInputArray, setActualInputArray] = React.useState([]);
    const [optionalInputsValues, setOptionalInputsValues] = React.useState([])
    const [saving, setSaving] = React.useState(false);

    const classes = useStyles();

    const generateInputList = () => {

        const maxActualInputCountIsFinite = Number.isFinite(Math.max(...actualInputCount))
        const actualInputCountValue = maxActualInputCountIsFinite ? Math.max(...actualInputCount) : 1

        const testCategory = document.getElementById('select_to_test').value
        setActualInputArray(actualInputParam => [...actualInputParam,
        {
            id: actualInputCountValue,
            name: `${testCategory} ${actualInputCountValue}`
        }
        ])

        setActualInputCount(actualParam => [...actualParam, actualInputCountValue + 1])
    }

    const handleRemoveInput = (id) => {
        const actualInputs = actualInputArray.filter(data => data.id !== id)
        setActualInputArray(actualInputs)
    }

    const handleSelectAOptionalInput = ({ position, value, inpuitName }) => {
        const optionalInputs = optionalInputsValues || []
        optionalInputs[position] = value
        setOptionalInputsValues(optionalInputs)
    }
    const handleSubmit = async (e) => {
        const form = new FormData();

        optionalInputsValues.forEach(dataParam => {
            form.set(dataParam.name, dataParam);
        })

        const filesUploaded = [
            markdownFile,
            caparoteiro,
            capasumario
        ]

        const [missingFiles] = new Set(filesUploaded.map(data => !data).filter(data => data))


        if (missingFiles) {
            toast.error(`Arquivo obrigatório ausente`);
        }

        const re = /(?:\.([^.]+))?$/;
        const extname = re.exec(markdownFile.name)[1]
        if (!extFilesSupported.includes(extname)) {
            return toast.error(`Formatos de arquivos suportados: ${extFilesSupported.map(data => data)} `)
        }
        form.set('markdownFile', markdownFile);
        form.set('background', background);
        form.set('caparoteiro', caparoteiro);
        form.set('capasumario', capasumario);

        setSaving(true);

        await fetch(`${process.env.REACT_APP_API_ORIGIN}/template`, {
            method: 'POST',
            body: form
        })
            .then(response => response.blob())
            .then(blob => {
                const file = window.URL.createObjectURL(blob);
                window.open(file);
                setSaving(false);
            })
            .catch((error) => console.error(error));
    };

    return (
        <form>
            <Grid container>
                <Grid item md={12} className={classes.grid}>
                    <label htmlFor="markdownFile">
                        <Paper
                            elevation={0}
                            variant="outlined"
                            square
                            className={classes.paper}

                        >
                            <TextField
                                type="file"
                                label="Outlined"
                                style={{ display: 'none' }}
                                variant="outlined"
                                id="markdownFile"
                                name="markdownFile"
                                onChange={(e) => setmarkdownFile(e.target.files[0])}
                            />

                            <p className={classes.labelParagraph} >
                                <span>Sumário Técnico</span>
                                <br />
                                <span>(*.zip)</span>
                                <br />
                                <br />
                                {
                                    markdownFile && (
                                        <span>
                                            {markdownFile.name}
                                        </span>
                                    )
                                }
                            </p>
                        </Paper>
                    </label>
                </Grid>

                <Grid item md={12} className={classes.grid}>
                    <label htmlFor="laboratorio">
                        <Paper
                            elevation={0}
                            variant="outlined"
                            square
                            className={classes.paper}

                        >
                            <TextField
                                type="file"
                                label="Outlined"
                                style={{ display: 'none' }}
                                variant="outlined"
                                id="laboratorio"
                                name="laboratorio"
                                onChange={(e) => setLaboratorio(e.target.files[0])}
                            />

                            <p className={classes.labelParagraph} >
                                <span>Capa do laboratorio</span>
                                <br />
                                <span>(*.jpg)</span>
                                <br />
                                <br />
                                {
                                    laboratorio && (
                                        <span>
                                            {laboratorio.name}
                                        </span>
                                    )
                                }
                            </p>
                        </Paper>
                    </label>
                </Grid>

                <Grid item md={12} className={classes.grid}>
                    <label htmlFor="caparoteiro">
                        <Paper
                            elevation={0}
                            variant="outlined"
                            square
                            className={classes.paper}

                        >
                            <TextField
                                type="file"
                                label="Outlined"
                                style={{ display: 'none' }}
                                variant="outlined"
                                id="caparoteiro"
                                name="caparoteiro"
                                onChange={(e) => setCapaRoteiro(e.target.files[0])}
                            />

                            <p className={classes.labelParagraph} >
                                <span>Capa do Roteiro</span>
                                <br />
                                <span>(*.jpg)</span>
                                <br />
                                <br />
                                {
                                    caparoteiro && (
                                        <span>
                                            {caparoteiro.name}
                                        </span>
                                    )
                                }
                            </p>
                        </Paper>
                    </label>
                </Grid>

                <Grid item md={12} className={classes.grid}>
                    <label htmlFor="capasumario">
                        <Paper
                            elevation={0}
                            variant="outlined"
                            square
                            className={classes.paper}

                        >
                            <TextField
                                type="file"
                                label="Outlined"
                                style={{ display: 'none' }}
                                variant="outlined"
                                id="capasumario"
                                name="capasumario"
                                onChange={(e) => setCapaSumario(e.target.files[0])}
                            />

                            <p className={classes.labelParagraph} >
                                <span>Capa do Sumário</span>
                                <br />
                                <span>(*.jpg)</span>
                                <br />
                                <br />
                                {
                                    capasumario && (
                                        <span>
                                            {capasumario.name}
                                        </span>
                                    )
                                }
                            </p>
                        </Paper>
                    </label>
                </Grid>

                {/* <Grid item md={12} className={classes.grid}>
                    <label htmlFor="background">
                        <Paper
                            elevation={0}
                            variant="outlined"
                            square
                            className={classes.paper}

                        >
                            <TextField
                                type="file"
                                label="Outlined"
                                style={{ display: 'none' }}
                                variant="outlined"
                                id="background"
                                name="background"
                                onChange={(e) => setBackGround(e.target.files[0])}
                            />

                            <p className={classes.labelParagraph} >
                                <span>BackGround</span>
                                <br />
                                <span>(*.jpg)</span>
                                <br />
                                <br />
                                {
                                    background && (
                                        <span>
                                            {background.name}
                                        </span>
                                    )
                                }
                            </p>
                        </Paper>
                    </label>
                </Grid> */}

                <Grid item md={12} className={classes.grid}>
                    <label htmlFor="background">
                        <Paper
                            elevation={0}
                            variant="outlined"
                            square
                            className={classes.paper}

                        >
                            <TextField
                                type="file"
                                label="Outlined"
                                style={{ display: 'none' }}
                                variant="outlined"
                                id="background"
                                name="background"
                                onChange={(e) => setBackGround(e.target.files[0])}
                            />

                            <p className={classes.labelParagraph} >
                                <span>BackGround</span>
                                <br />
                                <span>(*.jpg)</span>
                                <br />
                                <br />
                                {
                                    background && (
                                        <span>
                                            {background.name}
                                        </span>
                                    )
                                }
                            </p>
                        </Paper>
                    </label>
                </Grid>

                {
                    actualInputArray.map((data, index) => (
                        <Grid item md={12} className={classes.grid}>
                            <label htmlFor={data.name}>
                                <Paper
                                    elevation={0}
                                    variant="outlined"
                                    className={classes.paper}
                                >
                                    <TextField
                                        type="file"
                                        label="Outlined"
                                        style={{ display: 'none' }}
                                        variant="outlined"
                                        id={data.name}
                                        name={data.name}
                                        onChange={(e) => handleSelectAOptionalInput({
                                            position: index,
                                            name: data.name,
                                            value: e.target.files[0]
                                        })}
                                    />

                                    <p className={classes.labelParagraph} >
                                        <span>{data.name}</span>
                                        <br />
                                        <span>(*.png)</span>
                                        <br />
                                        <br />
                                        {
                                            optionalInputsValues.length > 0 && (
                                                <span>
                                                    {/* {optionalInputsValues[index].name} */}
                                                </span>
                                            )
                                        }
                                    </p>

                                </Paper>


                                <Button
                                    onClick={() => handleRemoveInput(data.id)}
                                    variant="text"
                                    style={buttonClosedInputStyle}
                                > Remover input
                                    <RemoveIcon />
                                </Button>

                            </label>
                        </Grid>
                    ))
                }

            </Grid>
            {
                saving
                    ? (
                        <Button
                            component="button"
                            variant="contained"
                            color="primary"
                            disabled
                        >Gerando...</Button>
                    )
                    : (
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Button
                                onClick={handleSubmit}
                                component="button"
                                variant="contained"
                                color="primary"
                            >Gerar Template</Button>

                            <aside style={{ display: 'flex', justifyContent: 'space-between' }}>

                                <select name="select_to_test" id='select_to_test'>
                                    <option value="pós">pós</option>
                                    <option value="pré">pré</option>
                                </select>
                                <Button
                                    onClick={() => generateInputList()}
                                    key='plus'
                                    style={buttonStyle}
                                > Adicionar input
                                    <AddIcon />
                                </Button>
                            </aside>
                        </div>
                    )
            }
            <ToastContainer />
        </form>
    )
}
