import AttachFileIcon from '@material-ui/icons/AttachFile';
import {Button} from 'react-admin'

const FileInput = ({record}) => {
    const id = (record['id'])
    const label = (record['adress'])

    const fileName = label.split('/')
    const name = fileName[3].split('-')

    const handleDownloadFile = async id => {
        await fetch(process.env.REACT_APP_API_ORIGIN + '/storage/' + id, {
            method: 'GET'
        }).then(response =>{
            window.open(response.url)
        })
    }

    return (
        <Button
        label={name[name.length - 1]}
        onClick={() => handleDownloadFile(id)}
        >
        <AttachFileIcon/>
        </Button>
    )
}

export default FileInput
