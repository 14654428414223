import React from "react";
import {
    Create,
    SimpleForm,
    TextInput,
} from 'react-admin';

const RequestTitle = ({ record }) => {
  return <span>Bug </span>;
};

export const BugCreate = props => (
    <Create title={<RequestTitle />} {...props}>
      <SimpleForm>
        <TextInput source="subject" fullWidth/>
        <TextInput source="description" fullWidth/>
      </SimpleForm>
    </Create>
);
