import * as React from "react";
import { useMediaQuery } from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import {
    List,
    Datagrid,
    TextField,
    NumberField,
    DateField,
    EditButton,
    Edit,
    Show,
    SimpleShowLayout,
    Create,
    Filter,
    SimpleForm,
    SimpleList,
    ReferenceInput,
    SelectInput,
    TextInput,
    NumberInput,
    DateTimeInput,
    ListButton,
} from 'react-admin';

const DQaFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Buscar" source="q" alwaysOn />
    </Filter>
);

export const DQaList = props => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
    <List filters={<DQaFilter />} {...props}>
            {isSmall ? (
        <SimpleList
            primaryText={record => record.title}
            secondaryText={record => `${record.views} views`}
            tertiaryText={record => new Date(record.published_at).toLocaleDateString()}
        />
        ) : (
        <Datagrid>
        <TextField source="id"/>
        <TextField source="practices_id"/>
        <DateField source="deadline" showTime />
        <TextField source="description"/>
        <NumberField source="weight" />
        <TextField source="status"/>
        <TextField source="timing"/>
        <TextField source="qa_id"/>
        <TextField source="category_demands_qa"/>
        <EditButton />
        </Datagrid>
        )}
    </List>
);
        }

export const DQaEdit = props => (
    <Edit {...props}>
        <SimpleForm>
        <TextInput source="practices_id" fullWidth/>
        <DateTimeInput source="deadline" showTime fullWidth />
        <TextInput source="description" fullWidth/>
        <NumberInput source="weight" fullWidth/>
        <TextInput source="status" fullWidth/>
        <TextInput source="timing" fullWidth/>
        <TextInput source="qa_id" fullWidth/>
        <TextInput source="category_demands_qa" fullWidth/>
            <ListButton label="Back" icon={<ChevronLeft />}/>
        </SimpleForm>
    </Edit>
);

export const DQaCreate = props => (
        <Create {...props}>
          <SimpleForm>
          <TextInput source="practices_id" fullWidth/>
        <DateTimeInput source="deadline" showTime fullWidth/>
        <TextInput source="description" fullWidth/>
        <NumberInput source="weight" fullWidth/>
        <TextInput source="status" fullWidth/>
        <TextInput source="timing" fullWidth/>
        <TextInput source="qa_id" fullWidth/>
        <ReferenceInput source="category_demands_qa" reference="category/demandquality" allowEmpty>
            <SelectInput optionText="name" />
        </ReferenceInput>
          </SimpleForm>
       </Create>
    );

    export const DQaShow = (props) => (
        <Show {...props}>
            <SimpleShowLayout>
            <TextField source="id"/>
        <TextField source="practices_id"/>
        <DateField source="deadline" showTime />
        <TextField source="description"/>
        <NumberField source="weight" />
        <TextField source="status"/>
        <TextField source="timing"/>
        <TextField source="qa_id"/>
        <TextField source="category_demands_qa"/>
            <ListButton label="Back" icon={<ChevronLeft />}/>
            </SimpleShowLayout>
        </Show>
    );
