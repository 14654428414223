import * as React from "react";
import { useMediaQuery } from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ButtonInput from '../components/ButtonInput'
import FileInput from '../components/FileInput'
import {
    List,
    Datagrid,
    TextField,
    NumberField,
    DateField,
    EditButton,
    Edit,
    Show,
    SimpleShowLayout,
    Create,
    Filter,
    SimpleForm,
    SimpleList,
    ReferenceInput,
    SelectInput,
    TextInput,
    ShowButton,
    NumberInput,
    DateTimeInput,
    ListButton,
    required,
    ArrayField,
    AutocompleteInput,
} from 'react-admin';

import { useHistory } from "react-router-dom";

const PraticeFilter = (props) => (
    <Filter {...props}>
        <ReferenceInput label="Buscar" source="q" reference="Pratice" alwaysOn allowEmpty emptyValue='' resettable>
            <AutocompleteInput optionText="name" />
        </ReferenceInput>
    </Filter>
);

export const PraticeList = props => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const history = useHistory()
    React.useEffect(() => {
        const userAuthenticated = localStorage.getItem('auth')
        if (!userAuthenticated) {
            history.push('/login')
        }
    })
    return (
        <List filters={<PraticeFilter />} {...props}>
            {isSmall ? (
                <SimpleList
                    primaryText={record => record.title}
                    secondaryText={record => `${record.views} views`}
                    tertiaryText={record => new Date(record.published_at).toLocaleDateString()}
                />
            ) : (
                <Datagrid>
                    <TextField source="name" />
                    <TextField source="version" />
                    <DateField source="deadline" showTime />
                    <NumberField source="status" />
                    <TextField source="fk_skills" />
                    <NumberField source="fk_request" />
                    <TextField source="fk_stakeholder" />
                    {/* <ButtonInput source="id" display="none"/> */}
                    <EditButton />
                    <ShowButton />
                </Datagrid>
            )}
        </List>
    );
}

export const PraticeEdit = props => {
    const history = useHistory()
    React.useEffect(() => {
        const userAuthenticated = localStorage.getItem('auth')
        if (!userAuthenticated) {
            history.push('/login')
        }
    })
    return (
        <Edit  {...props}>
            <SimpleForm>
                <TextField source="id" />
                <TextInput source="name" fullWidth />
                <TextInput source="version" disabled fullWidth />
                <DateTimeInput source="deadline" fullWidth />
                <SelectInput source="status" choices={[
                    { id: 'approved', name: 'approved' },
                    { id: 'disapproved', name: 'disapproved' },
                ]} />
                <ReferenceInput source="fk_skills" reference="Skill" allowEmpty>
                    <SelectInput optionText="name" />
                </ReferenceInput>
                <ReferenceInput source="fk_request" reference="Request" allowEmpty>
                    <SelectInput optionText="number" />
                </ReferenceInput>
                <ReferenceInput source="fk_stakeholder" reference="StakeHolder" allowEmpty>
                    <SelectInput optionText="name" />
                </ReferenceInput>
                <TextInput source="justification" fullWidth />
                <ListButton label="Back" icon={<ChevronLeft />} />
            </SimpleForm>
        </Edit>
    )
}


const validateFirstName = [required()];

export const PraticeCreate = props => {
    const history = useHistory()
    React.useEffect(() => {
        const userAuthenticated = localStorage.getItem('auth')
        if (!userAuthenticated) {
            history.push('/login')
        }
    })

    return (
        <Create {...props}>
            <SimpleForm>
                <TextInput source="name" fullWidth validate={validateFirstName} />
                <TextInput source="version" fullWidth disabled defaultValue={1} />
                <DateTimeInput source="deadline" fullWidth defaultValue={new Date()} />
                <NumberInput source="status" fullWidth disabled defaultValue={0} />
                <ReferenceInput source="skill" reference="Skill" allowEmpty validate={validateFirstName}>
                    <SelectInput optionText="name" />
                </ReferenceInput>
                <ReferenceInput source="fk_request" reference="Request" allowEmpty validate={validateFirstName}>
                    <SelectInput optionText="number" />
                </ReferenceInput>
                <ReferenceInput source="fk_stakeholder" reference="StakeHolder" allowEmpty validate={validateFirstName}>
                    <SelectInput optionText="name" />
                </ReferenceInput>
                <TextInput source="justification" fullWidth validate={validateFirstName} />
            </SimpleForm>
        </Create>
    )
};

export const PraticeShow = (props) => {
    const history = useHistory()
    React.useEffect(() => {
        const userAuthenticated = localStorage.getItem('auth')
        if (!userAuthenticated) {
            history.push('/login')
        }
    })
    return (
        <Show {...props}>
            <SimpleShowLayout>
                <TextField source="id" />
                <ArrayField source="adresses">
                    <Datagrid>
                        <FileInput />
                    </Datagrid>
                </ArrayField>
                <TextField source="name" />
                <TextField source="version" />
                <DateField source="deadline" showTime />
                <NumberField source="status" />
                <NumberField source="fk_request" />
                <TextField source="justification" />
                <ListButton label="Back" icon={<ChevronLeft />} />
            </SimpleShowLayout>
        </Show>
    )
}
