import React, { useEffect, useContext, useState } from 'react'
import PostCreateToolbar from './PostCreateToolbar'
import {
  Create,
  SimpleForm,
  SelectInput
} from 'react-admin';

import axios from 'axios'

import LocationContext from '../../../contexts/LocalizationContext'

function ChoosePratice(props) {
  const [pratices, setPratices] = useState([])
  const [versions, setVersions] = useState([])
  const [save, setSave] = useState(false)

  const { 
    setPratice,
    setStep,
    step,
    token
  } = useContext(LocationContext)

  const config = {
    headers: {
      authorization: `Bearer ${token}`,
    }
  }

  const handleChangePratice = async ({ target: { value } }) => {
    if (!value) return

    const responsePratice = await axios.get(`${process.env.REACT_APP_API_ORIGIN}/pratice/${value}`, config)
    const responseVersions = await axios.get(`${process.env.REACT_APP_API_ORIGIN}/pratice_version/${value}`, config)

    setPratice(responsePratice.data || [])
    setVersions(responseVersions.data || [])
  }

  useEffect(() => {
    token && axios.get(`${process.env.REACT_APP_API_ORIGIN}/pratices_filtered_by_name`, config)
      .then(response => setPratices(response.data || []))
  }, [token])

  return (
    <div className="pai" style={{ position: 'relative' }}>
      <Create {...props}>
        <SimpleForm toolbar={
          <PostCreateToolbar
            { ...props }
            disabled={save}
            save={() => setStep(step + 1)}
          />
        }>
          <h1>Nova Localização</h1>
          <SelectInput
            source="name"
            style={{ width: '20%' }}
            choices={pratices}
            onChange={handleChangePratice}
          />
          { versions.length ? 
            <SelectInput
              source="version"
              optionText='version'
              choices={versions}
              onChange={() => setSave(true)}
            /> 
            : ''
          }
        </SimpleForm>
      </Create>
    </div>
  )
}

export default ChoosePratice