import React from 'react'
import LocationContext from '../../../contexts/LocalizationContext'

import {
    SimpleForm,
    Toolbar,
    Button,
    Link,
    TextInput,
    Title,
} from 'react-admin'


import { LOCALIZATION } from '../../../services/api'

import '../../../css/newEntry.css'

const EntryKeyTranslation = props => {
    const {
        step,
        setStep,
        pratice,
        language,
        auth,
        translator,
        expressions,
        setLocalization,
        setContent
    } = React.useContext(LocationContext)


    const PostCreateToolbar = props => (
        <Toolbar {...props} >
            <Button
                label="Salvar Nova Versão"
                key='LocationInput'
                component={Link}
                onClick={handleNextPage}
            >
            </Button>
        </Toolbar>
    );


    const handleNextPage = async () => {
        const translate = expressions.translates.filter(translate => translate)
        const data = {
            ...expressions,
            translates: translate.length ? translate : []
        }

        const { data: response } = await LOCALIZATION.post(data)

        setContent(expressions?.translates)
        setLocalization(response[0])
        setStep(step + 1)
    }


    return (
        <div className="pai" style={{ position: 'relative' }}>
            <SimpleForm toolbar={<PostCreateToolbar />}>
                <Title title="Localizações" />
                <div style={{ backgroundColor: 'white', width: 'auto', position: 'relative', padding: '2em' }}>
                    <div>
                        <p>Nome da Prática: {pratice?.name}</p>
                        <p>Versão da Prática: {pratice?.version}</p>
                        <p>Idioma: {language?.name}</p>
                        <p>Versão da Entrada: </p>
                        <input type="hidden" name="author" value={auth?.id}></input>
                        <div style={{ marginBottom: '1em' }}>
                            <button className="seleExp" type="button" disabled>Selecionar todas as expressões</button>
                            <button className="desseExp" type="button" disabled>Desselecionar todas as expresões</button>
                            <button className="traduSele" type="button" disabled>Traduzir selecionados</button>
                        </div>

                        <TextInput
                            source="translator"
                            defaultValue={translator}
                            label="Tradutor"
                            disabled
                        />
                    </div>
                    {expressions &&
                        expressions?.translates.map((translate) => (
                            <form>
                                <div>
                                    <div class="box">
                                        <input class="check" type="checkbox" disabled checked name="horns" />
                                        <label class="check1">Incluir Expressão</label>

                                        <div style={{ marginTop: '.5em' }}>
                                            <label>
                                                Versão
                                                <input id="version" name="version" value={`v${translate.version}`} disabled placeholder="v1" style={{ padding: '.5em' }} />
                                            </label>
                                        </div>
                                        <textarea
                                            class="text1"
                                            name="story"
                                            id="key"
                                            rows="10"
                                            cols="43"
                                            placeholder="chave"
                                            disabled
                                        >
                                            {decodeURIComponent(translate.form[0])}
                                        </textarea>
                                        <textarea
                                            class="text2"
                                            name="story"
                                            id="value"
                                            rows="10"
                                            cols="43"
                                            placeholder="valor"
                                            disabled
                                        >
                                            {decodeURIComponent(translate.value)}
                                        </textarea>
                                    </div>
                                </div>
                            </form>
                        ))
                    }
                </div>
            </SimpleForm>
        </div>
    );
}

export default EntryKeyTranslation
