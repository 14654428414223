import axios from "axios";
import * as React from "react";
import { useMediaQuery } from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import {
  Toolbar,
  Button,
  Link,
  SimpleForm,
  Create,
  useNotify,
  TextField,
  Edit,
  ListButton,
  Filter,
  TextInput,
  List,
  SimpleList,
  Datagrid,
  AutocompleteInput,
  ReferenceInput,
} from 'react-admin';


export const KeynameCreate = props => {
  const notify = useNotify()
  const [key, setKey] = React.useState()

  const onFailure = (errors) => {
    const { body } = errors
    const error = body.map(error => error.message)

    notify(error.toString(), 'error')
  }

  const handleSubmit = () => {
    if(!key) {
      return;
    }

    const auth = localStorage.getItem('auth')
    const { token } = JSON.parse(auth)

    axios.post(`${process.env.REACT_APP_API_ORIGIN}/keyname`, { name: key }, {
      headers: {
        authorization: `Bearer ${token}`,
      }
    }).then(({ data }) => {
      window.location = '/keyname'
    })
  }

  const PostCreateToolbar = props => (
    <Toolbar {...props} >
      <Button
        label="Criar"
        key='NewTranslation'
        component={Link}
        onClick={handleSubmit}
      >
      </Button>
    </Toolbar>
  );

  return (
    <Create {...props} onFailure={onFailure} toolbar={<PostCreateToolbar />}>
      <SimpleForm toolbar={<PostCreateToolbar />}>
        <h3>Criar Chave</h3>
        <textarea
          placeholder="chave"
          style={{ resize: 'none' }}
          rows="15"
          onChange={({ target }) => setKey(target.value)}
        />
      </SimpleForm>
    </Create>
  );

}

export const KeynameEdit = props => (
  <Edit {...props}>
      <SimpleForm>
      <TextField source="id"/>
      <TextField source="name"/>
      <TextField source="updated_at" fullWidth multiline rowsMax={4}/>
      <TextField source="created_at"/>
      <ListButton label="Voltar" icon={<ChevronLeft />}/>
      </SimpleForm>
  </Edit>
);

const PraticeFilter = (props) => (
    <Filter {...props}>
        <ReferenceInput label="Buscar" source="q" reference="Keyname" alwaysOn allowEmpty emptyValue='' resettable>
        <AutocompleteInput optionText="name" />
</ReferenceInput>
    </Filter>
);

export const KeynameList = props => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
    <List filters={<PraticeFilter />} {...props}>
            {isSmall ? (
        <SimpleList
            primaryText={record => record.title}
            secondaryText={record => `${record.views} views`}
            tertiaryText={record => new Date(record.published_at).toLocaleDateString()}
        />
        ) : (
        <Datagrid>
          <TextField source="id"/>
         <TextField source="name"/>
      <TextField source="updated_at"/>
      <TextField source="created_at"/>
        </Datagrid>
        )}
    </List>
);
        }
