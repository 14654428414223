import * as React from "react";
import { Admin, Resource, resolveBrowserLocale } from 'react-admin';
import { RequestList, RequestEdit, RequestCreate, RequestShow } from './pages/Posts';
import { ClientList, ClientEdit, ClientCreate, ClientShow } from './pages/stakeholder/Clients';
import { PraticeList, PraticeCreate, PraticeEdit, PraticeShow  } from "./pages/pratices";
import { UserList, UserCreate, UserEdit, UserShow  } from "./pages/users";
import PostIcon from '@material-ui/icons/Book';
import TodayIcon from '@material-ui/icons/Today';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import ChromeReaderModeIcon from '@material-ui/icons/ChromeReaderMode';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import CategoryIcon from '@material-ui/icons/Category';
import BookmarksIcon from '@material-ui/icons/Bookmarks';
import TranslateIcon from '@material-ui/icons/Translate';
import UserIcon from '@material-ui/icons/Group';
import InfoIcon from '@material-ui/icons/Description'
import Dashboard from './pages/Dashboard';
import authProvider from './providers/authProvider';
import MyLoginPage from './pages/MyLoginPage';
import ptBrMessages from 'ra-language-pt-br'
import enMessages from 'ra-language-english'
import polyglotI18nProvider from 'ra-i18n-polyglot'
import englishMessages from './languages/en'
import portugueseMessages from './languages/pt_br'
import { ProfileEdit } from './pages/profile'
import { Route } from 'react-router'
import MyLayout from './layout/MyLayout'
import {BugCreate} from './pages/bug'
import DataProvider from './providers/dataProvider'
import {StorageCreate} from './pages/storage'
import { DemandsList } from "./pages/demands/Demands";
import { DModelCreate, DModelEdit, DModelList } from "./pages/demands/Demand_model";
import { DevCreate, DevEdit, DevList } from "./pages/demands/demand_dev";
import { CDModelCreate, CDModelEdit, CDModelList } from "./pages/demands/modeling_demand_category";
import { DQaCreate, DQaEdit, DQaList } from "./pages/demands/Demand_quality";
import { CDDevCreate, CDDevEdit, CDDevList } from "./pages/demands/dev_demand_category";
import { CDQaCreate, CDQaEdit, CDQaList } from "./pages/demands/Qa_demand_category";
import { StakeHCreate, StakeHEdit, StakeHList } from "./pages/stakeholder/Stakeholder";
import { StakeCCreate, StakeCEdit, StakeCList } from "./pages/stakeholder/StakeHolder_category";
import { SkillsCreate, SkillsEdit, SkillsList } from "./pages/Skills";
import DemoApp from "./pages/Calendar";
import { TemplateCreate } from './pages/Templates';
import { TranslCreate, TranslEdit, TranslList } from "./pages/translation/Expressions/Translation";
import * as domainMessages from "react-admin-import-csv/lib/i18n";
import { SPList } from "./pages/storage_pratice";
import { NTCreate } from "./pages/translation/Expressions/newTranslation";
import { LICreate } from "./pages/translation/Localizations/locationinput";
import { NVLICreate } from "./pages/translation/Localizations/NewVersionForLocationInput";
import { NEList } from "./pages/translation/Expressions/newExpression";
import { LanguageEdit, LanguageList, LanguageCreate } from "./pages/translation/language";
import { LocalCreate,LocalShow, LocalList } from "./pages/translation/Localizations/localizations";
import { RequesCreate, RequesList } from "./pages/translation/Requests/requests";
import { TRCreate } from "./pages/translation/Requests/translationRequest";
import { KeynameCreate, KeynameEdit, KeynameList } from './pages/translation/Keyname'

const messages = {
  'pt-br': {
    ...ptBrMessages,
    ...portugueseMessages
  },
  en: {
    ...enMessages,
    ...englishMessages,
    ...domainMessages.en
  }
}

const i18nProvider = polyglotI18nProvider(
  locale => messages[locale] ? messages[locale] : messages['pt-br'],
  resolveBrowserLocale()
)

const App = () => (
  <Admin
  layout={MyLayout}
  i18nProvider={i18nProvider}
  dashboard={Dashboard}
  loginPage={MyLoginPage}
  authProvider={authProvider}
  dataProvider={DataProvider}
  customRoutes={[ <Route
    key='profile'
    path='/profile'
    render={() => <ProfileEdit />}
  />,
    <Route
    key='storage'
    path='/storage/:id'
    render={() => <StorageCreate />}
  />,
  <Route
    key='template'
    path='/template/create'
    render={() => <TemplateCreate />}
  />,
  <Route
  key='NewVersion'
  path='/NewVersionForLocationInput'
  render={() => <NVLICreate/> }
  />,
  <Route
  key='LocationInput'
  path='/LocationInput'
  render={() => <LICreate/>}
  />,
  <Route
  key='TranslationRequest'
  path='/TranslationRequest'
  render={() => <TRCreate/>}
  />,
  <Route
  key='NewTranslation'
  path='/NewTranslation'
  render={() => <NTCreate/>}
  />,
]}
  >
    <Resource key='Client' name="Client" list={ClientList} edit={ClientEdit} create={ClientCreate} show={ClientShow} icon={UserIcon} />
    <Resource key='StakeHolder' name="StakeHolder" create={StakeHCreate} edit={StakeHEdit} list={StakeHList} icon={SupervisedUserCircleIcon} />
    <Resource key='Request' name="Request" list={RequestList} edit={RequestEdit} create={RequestCreate} show={RequestShow} icon={InfoIcon} />
    <Resource key="Pratice" name="Pratice" list={PraticeList} edit={PraticeEdit} create={PraticeCreate} show={PraticeShow} icon={PostIcon}/>
    <Resource key="demandmodeling" name="demandmodeling" show={DModelList} create={DModelCreate} edit={DModelEdit} />
    <Resource key="category/demandmodeling" name="category/demandmodeling" create={CDModelCreate} edit={CDModelEdit} show={CDModelList} />
    <Resource key="category/demanddev" name="category/demanddev" create={CDDevCreate} edit={CDDevEdit} show={CDDevList}/>
    <Resource key="category/demandquality" name="category/demandquality" create={CDQaCreate} edit={CDQaEdit} show={CDQaList}/>
    <Resource key="demandquality" name="demandquality" create={DQaCreate} edit={DQaEdit} show={DQaList}/>
    <Resource key="Skill" name="Skill" create={SkillsCreate} edit={SkillsEdit} list={SkillsList} />
    <Resource key="Calendar" name="Calendar" list={DemoApp} icon={TodayIcon} />
    <Resource key="Storage" name="Storage"/>
    <Resource key='Infra' name="Infra"  />
    <Resource key='Demand' name="Demand" list={DemandsList} icon={BookmarksIcon}  />
    <Resource key='category/stakeholder' name="category/stakeholder" create={StakeCCreate} edit={StakeCEdit} list={StakeCList} icon={CategoryIcon} />
    <Resource key='User' name="User" list={UserList} edit={UserEdit} create={UserCreate} show={UserShow} icon={AssignmentIndIcon} />
    <Resource key='demandsdev' name="demandsdev" show={DevList} create={DevCreate} edit={DevEdit} />
    <Resource key='Role' name="Role" />
    <Resource key='language' name="Languages" list={LanguageList} icon={TranslateIcon}/>
    <Resource key='New version for location input' name="NewVersionForLocationInput" create={NVLICreate}/>
    <Resource key='NewTranslation' name="NewTranslation" create={NTCreate}/>
    <Resource key='Localization' name="Localizations" list={LocalList} create={LocalCreate} show={LocalShow} icon={TranslateIcon}/>
    <Resource key="Translation" name="Translation" list={TranslList} create={TranslCreate} edit={TranslEdit} icon={TranslateIcon}/>
    {/* <Resource key="Requests" name="Request-Translation" list={RequesList} create={RequesCreate} icon={TranslateIcon}/> */}
    <Resource key="TranslationRequest" name="TranslationRequest" create={TRCreate} icon={TranslateIcon}/>
    <Resource key="NewLocalization" name="NewLocalization"/>
    <Resource key='NewExpression' name="NewExpression"/>
    <Resource key='keyname' name='Keyname' list={KeynameList} edit={KeynameEdit} icon={TranslateIcon} />
    <Resource key='LocationInput' name="LocationInput" create={LICreate}/>
    <Resource key='pratice_storage/:id' name="pratice_storage/:id" />
    <Resource key='Bug' name="Bug" create={BugCreate} />
    <Resource key='template' name="Template" list={TemplateCreate} create={{}} icon={ChromeReaderModeIcon} />
  </Admin>
);

export default App;
