import * as React from "react";
import { useMediaQuery } from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import {
    List,
    Datagrid,
    TextField,
    NumberField,
    DateField,
    EditButton,
    Edit,
    Show,
    SimpleShowLayout,
    Create,
    Filter,
    SimpleForm,
    SimpleList,
    ReferenceInput,
    SelectInput,
    TextInput,
    ShowButton,
    NumberInput,
    DateTimeInput,
    ListButton,
    required,
    ReferenceField,
    AutocompleteInput,
} from 'react-admin';

import { useHistory } from "react-router-dom";

const RequestFilter = (props) => (
    <Filter {...props}>
        <ReferenceInput label="Buscar" source="q" reference="Request" alwaysOn allowEmpty emptyValue='' resettable>
            <AutocompleteInput optionText="status" />
        </ReferenceInput>
        <ReferenceInput label="Request" source="RequestId" reference="Request" allowEmpty>
            <SelectInput optionText="deadline" />
        </ReferenceInput>
    </Filter>
);

export const RequestList = props => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const history = useHistory()
    React.useEffect(() => {
        const userAuthenticated = localStorage.getItem('auth')
        if (!userAuthenticated) {
            history.push('/login')
        }
    })
    return (
        <List filters={<RequestFilter />} {...props}>
            {isSmall ? (
                <SimpleList
                    primaryText={record => record.title}
                    secondaryText={record => `${record.views} views`}
                    tertiaryText={record => new Date(record.published_at).toLocaleDateString()}
                />
            ) : (
                <Datagrid>
                    <ReferenceField source="fk_clients" reference="Client">
                        <TextField source="name" />
                    </ReferenceField>
                    <NumberField source="number" />
                    <DateField source="deadline" showTime />
                    <TextField source="status" />
                    <EditButton />
                    <ShowButton />
                </Datagrid>
            )}
        </List>
    );
}

const RequestTitle = ({ record }) => {
    return <span>Requests </span>;
};

export const RequestEdit = props => {

    const history = useHistory()
    React.useEffect(() => {
        const userAuthenticated = localStorage.getItem('auth')
        if (!userAuthenticated) {
            history.push('/login')
        }
    })
    return (

        <Edit {...props}>
            <SimpleForm>
                <TextField source="id" />
                <NumberField source="number" />
                <DateTimeInput source="deadline" />
                <NumberField source="status" />
                <ReferenceInput source="fk_clients" reference="Client" allowEmpty>
                    <SelectInput optionText="name" />
                </ReferenceInput>
                <ListButton label="Back" icon={<ChevronLeft />} />
            </SimpleForm>
        </Edit>
    )
};

const validateFirstName = [required()];

export const RequestCreate = props => {
    const history = useHistory()
    React.useEffect(() => {
        const userAuthenticated = localStorage.getItem('auth')
        if (!userAuthenticated) {
            history.push('/login')
        }
    })
    return (
        <Create title={<RequestTitle />} {...props}>
            <SimpleForm>
                <NumberInput source="number" disabled />
                <DateTimeInput source="deadline" validate={validateFirstName} />
                <TextInput source="status" disabled defaultValue={0} />
                <ReferenceInput source="fk_clients" reference="Client" allowEmpty validate={validateFirstName}>
                    <SelectInput optionText="name" />
                </ReferenceInput>
            </SimpleForm>
        </Create>
    );
}

export const RequestShow = (props) => {
    const history = useHistory()
    React.useEffect(() => {
        const userAuthenticated = localStorage.getItem('auth')
        if (!userAuthenticated) {
            history.push('/login')
        }
    })
    return (
        <Show {...props}>
            <SimpleShowLayout>
                <TextField source="id" />
                <NumberField source="number" />
                <DateField source="deadline" showTime />
                <TextField source="status" />
                <ListButton label="Back" icon={<ChevronLeft />} />
            </SimpleShowLayout>
        </Show>
    );

}
