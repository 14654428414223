import * as React from "react";
import { useMediaQuery } from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import AddIcon from '@material-ui/icons/Add';
import {
    List,
    Datagrid,
    TextField,
    EditButton,
    Edit,
    Create,
    Filter,
    SimpleForm,
    SimpleList,
    ReferenceInput,
    SelectInput,
    TextInput,
    ListButton,
    EmailField,
    Button,
    Link,
    ReferenceField,
    AutocompleteInput,
} from 'react-admin';

import { useHistory } from "react-router-dom";

const buttonStyle = {
    width: '37px',
    height: '37px',
    fontsize: '20px',
    backgroundColor: '',
    bordercolor: '#259b4c',
    borderRadius: '30px',
    display: 'flex',
    justifyContent: 'center',
};

const StakeHFilter = (props) => {
    const history = useHistory()
    React.useEffect(() => {
        const userAuthenticated = localStorage.getItem('auth')
        if (!userAuthenticated) {
            history.push('/login')
        }
    })
    return (
        <Filter {...props}>
            <ReferenceInput label="Buscar" source="q" reference="StakeHolder" alwaysOn allowEmpty emptyValue='' resettable>
                <AutocompleteInput optionText="name" />
            </ReferenceInput>
            <ReferenceInput label="Request" source="RequestId" reference="Request" allowEmpty>
                <SelectInput optionText="deadline" />
            </ReferenceInput>
        </Filter>
    )
};

export const StakeHList = props => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const history = useHistory()
    React.useEffect(() => {
        const userAuthenticated = localStorage.getItem('auth')
        if (!userAuthenticated) {
            history.push('/login')
        }
    })
    return (
        <List filters={<StakeHFilter />} {...props}>
            {isSmall ? (
                <SimpleList
                    primaryText={record => record.title}
                    secondaryText={record => `${record.views} views`}
                    tertiaryText={record => new Date(record.published_at).toLocaleDateString()}
                />
            ) : (
                <Datagrid>
                    <TextField source="id" />
                    <TextField source="name" />
                    <EmailField source="email" />
                    <TextField source="cpf" />
                    <ReferenceField source="fk_clients" reference="Client">
                        <TextField source="name" />
                    </ReferenceField>
                    <ReferenceField source="fk_category_stakeholder" reference="category/stakeholder">
                        <TextField source="name" />
                    </ReferenceField>
                    <EditButton />
                </Datagrid>
            )}
        </List>
    );
}

export const StakeHEdit = props => {
    const history = useHistory()
    React.useEffect(() => {
        const userAuthenticated = localStorage.getItem('auth')
        if (!userAuthenticated) {
            history.push('/login')
        }
    })
    return (
        <Edit {...props}>
            <SimpleForm>
                <TextInput source="name" fullWidth />
                <TextInput source="email" fullWidth />
                <TextInput source="cpf" fullWidth />
                <ReferenceInput source="fk_clients" reference="Client" allowEmpty>
                    <SelectInput optionText="name" />
                </ReferenceInput>
                <ReferenceInput source="fk_category_stakeholder" reference="category/stakeholder" allowEmpty>
                    <SelectInput optionText="name" />
                </ReferenceInput>
                <ListButton label="Back" icon={<ChevronLeft />} />
            </SimpleForm>
        </Edit>
    )
};

export const StakeHCreate = props => {
    const history = useHistory()
    React.useEffect(() => {
        const userAuthenticated = localStorage.getItem('auth')
        if (!userAuthenticated) {
            history.push('/login')
        }
    })
    return (
        <Create {...props}>
            <SimpleForm>
                <TextInput source="name" fullWidth />
                <TextInput source="email" fullWidth />
                <TextInput source="cpf" fullWidth />
                <ReferenceInput source="fk_clients" reference="Client" allowEmpty>
                    <SelectInput optionText="name" />
                </ReferenceInput>
                <ReferenceInput source="fk_category_stakeholder" reference="category/stakeholder" allowEmpty>
                    <SelectInput optionText="name" />
                </ReferenceInput>
                <Button
                    key='plus'
                    component={Link}
                    to={{
                        pathname: "/category/stakeholder",
                    }}
                    style={buttonStyle}
                >
                    <AddIcon />
                </Button>
            </SimpleForm>
        </Create>
    )
}
