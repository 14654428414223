import * as React from "react";
import { useMediaQuery } from '@material-ui/core';
import {
    List,
    Datagrid,
    Filter,
    SimpleList,
    Show,
    SimpleShowLayout,
    TextField,
    Edit,
    SimpleForm,
    Create,
    TextInput,
    EditButton,
    ShowButton,
    required,
    regex,
    ReferenceInput,
    AutocompleteInput,
} from 'react-admin';

import { useHistory } from "react-router-dom";

const ClientFilter = (props) => (
    <Filter {...props}>
        <ReferenceInput label="Buscar" source="q" reference="Client" alwaysOn allowEmpty emptyValue='' resettable>
            <AutocompleteInput optionText="name" />
        </ReferenceInput>
    </Filter>
);

export const ClientList = props => {
    const history = useHistory()
    React.useEffect(() => {
        const userAuthenticated = localStorage.getItem('auth')
        if (!userAuthenticated) {
            history.push('/login')
        }
    })

    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
        <List filters={<ClientFilter />} {...props}>
            {isSmall ? (
                <SimpleList
                    primaryText={record => record.title}
                    secondaryText={record => `${record.views} views`}
                    tertiaryText={record => new Date(record.published_at).toLocaleDateString()}
                />
            ) : (
                <Datagrid rowClick="edit">
                    <TextField source="id" />
                    <TextField source="name" />
                    <TextField source="cpf_cnpj" />
                    <EditButton />
                    <ShowButton />
                </Datagrid>
            )}
        </List>
    );
}
export const ClientEdit = props => {
    const history = useHistory()
    React.useEffect(() => {
        const userAuthenticated = localStorage.getItem('auth')
        if (!userAuthenticated) {
            history.push('/login')
        }
    })
    return (
        <Edit {...props}>
            <SimpleForm>
                <TextField source="id" />
                <TextInput source="name" fullWidth />
                <TextInput source="cpf_cnpj" fullWidth />
            </SimpleForm>
        </Edit>
    )
}
const validateFirstName = [required()];
const validateZipCode = [required(), regex(/^([0-9]{3}\.?[0-9]{3}\.?[0-9]{3}\-?[0-9]{2}|[0-9]{2}\.?[0-9]{3}\.?[0-9]{3}\/?[0-9]{4}\-?[0-9]{2})$/, 'Must be a valid Zip Code')];

export const ClientCreate = props => {
    const history = useHistory()
    React.useEffect(() => {
        const userAuthenticated = localStorage.getItem('auth')
        if (!userAuthenticated) {
            history.push('/login')
        }
    })
    return (
        <Create {...props}>
            <SimpleForm>
                <TextInput source="name" fullWidth validate={validateFirstName} />
                <TextInput source="cpf_cnpj" fullWidth validate={validateZipCode} />
            </SimpleForm>
        </Create>
    )
}

export const ClientShow = (props) => {
    const history = useHistory()
    React.useEffect(() => {
        const userAuthenticated = localStorage.getItem('auth')
        if (!userAuthenticated) {
            history.push('/login')
        }
    })
    return (
        <Show {...props}>
            <SimpleShowLayout>
                <TextField source="id" />
                <TextField source="name" />
                <TextField source="cpf_cnpj" />
            </SimpleShowLayout>
        </Show>
    )
};
