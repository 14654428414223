import API from '../config'

const CHECK_DUPLICATE_EXPRESSION = {
  get: (params) => API.get(`/check-duplicate-expressions`, {
    params: {
      fk_keyname: params.fk_keyname,
      fk_language: params.fk_language,
      value: params.value
    }
  })
}

const TRANSLATE_NEXT_VERSION = {
  get: (params) => API.get(`translates/language/${params.fk_language}/keyname/${params.fk_keyname}/versions`)
}

export {
  CHECK_DUPLICATE_EXPRESSION,
  TRANSLATE_NEXT_VERSION
}
